import { createSlice } from '@reduxjs/toolkit'
import { getAllGstDueDates, getGstDueDate } from '../MiddleWare/GstDueDates';

const initialState = {
    gstDueDatesData: [],
    gstDueDatesMaster: {},
    gstDueDates: null
};

const gstDueDatesSlice = createSlice({
    name: 'gstDueDates',
    initialState,
    reducers: {
        setGstDueDates(state, action) {
            return {
                ...state,
                gstDueDatesData: action.payload ? action.payload.company : [],
            };
        },
        updateGstDueDates(state, action) {
            const oldData = [...state.gstDueDatesData];
            const newdata = oldData.filter((due) => due._id !== action.payload._id);
            newdata.push(action.payload);

            return {
                ...state,
                gstDueDatesData: newdata || [],
            };
        },
        createGstDueDates(state, action) {
            const newdata = [...state.gstDueDatesData];
            newdata.push(action.payload);

            return {
                ...state,
                gstDueDatesData: newdata || [],
            };
        }
    },
    extraReducers: (builder) => {
        // Add reducers for additional action types here, and handle loading state as needed
        builder.addCase(getAllGstDueDates.pending, () => { })

        builder.addCase(getAllGstDueDates.fulfilled, (state, action) => {
            if (action.payload) {
                state.gstDueDatesData = action.payload
                state.gstDueDatesMaster = action.payload?.gstDueDateMaster || {}
            }
        })

        builder.addCase(getAllGstDueDates.rejected, (state, action) => {
            if (action.payload) {
                state.error = action.payload.message
                state.loading = true;
            }
        })

        // Add reducers for additional action types here, and handle loading state as needed
        builder.addCase(getGstDueDate.pending, () => { })

        builder.addCase(getGstDueDate.fulfilled, (state, action) => {
            if (action.payload) {
                state.gstDueDates = action.payload
            }
        })

        builder.addCase(getGstDueDate.rejected, (state, action) => {
            if (action.payload) {
                state.error = action.payload.message
                state.loading = true;
            }
        })
    }
})

export const { createGstDueDate, setGstDueDate, updateGstDueDate } = gstDueDatesSlice.actions
export default gstDueDatesSlice.reducer