import React, { useState, useEffect } from 'react';
import { Button, Drawer, Space } from 'antd';
import _ from "lodash";
// Css
import moment from 'moment';
import { useDispatch } from 'react-redux';
import InputContainer from '../../../../components/InputContainer/InputContainer';
import { Confirmation, ShowMessage } from '../../../../components/Utils';
import { createItDueDate, deleteItDueDate, getAllItDueDates, updateItDueDate, updateItDueDateHistory } from '../../../../STORE/MiddleWare/ItDueDates';

const initData = {
  id: null,
  itrIndividualOpeningDate: "",
  itrIndividualDueDate: "",
  itrCompanyOpeningDate: "",
  itrCompanyDueDate: "",
}

// eslint-disable-next-line react/prop-types
const DrawerIt = ({ show, setShow, drawerType, rowValue }) => {
  const [open, setOpen] = useState(false);
  const [mode, setMode] = useState('view');
  const [formErrors, setFormErrors] = useState({});
  const [data, setData] = useState({})

  const dispatch = useDispatch();

  useEffect(() => {
    setOpen(show);
    setFormErrors({});
    setMode(drawerType === 'new' ? "edit" : "view")
    // eslint-disable-next-line react/prop-types
    if (rowValue?._id) {
      setData({ ...rowValue });
    } else {
      setData(initData);
    }
  }, [show, rowValue, drawerType]);

  const onClose = () => {
    setOpen(false);
    setShow(false);
    setMode('view');
  };

  const onDelete = () => {

    Confirmation("Are you sure, you want to delete this DueDates?", "", "", ["No", "YES"], true).then(
      (isConfirm) => {
        if (isConfirm) {
          const dueDates = { _id: data._id };
          dispatch(deleteItDueDate(dueDates)).then((res) => {
            if (res?.payload?.message === "Data Deleted Successfully") {
              ShowMessage("success", "Due Dates Deleted Successfully");
              dispatch(getAllItDueDates());
              onClose();
            }
          });
        }
      }
    );
  };

  const handleChange = (name, e) => {
    const updatedData = { ...data, [name]: e.target.value }
    setData(updatedData)
  };

  const handleSelectChangeHandler = (nameField, valueField, valueObject) => {
    if (valueObject[valueField]) {
      setFormErrors({ ...formErrors, [nameField]: "" })
    }
    setData({ ...data, [nameField]: valueObject[valueField] });
  };

  const validate = (values) => {
    const errors = {};

    if (!values.itrIndividualOpeningDate) { errors.itrIndividualOpeningDate = 'Please Select Opening Date'; }
    if (!values.itrIndividualDueDate) { errors.itrIndividualDueDate = 'Please Select Due Date'; }
    if (!values.itrCompanyOpeningDate) { errors.itrCompanyOpeningDate = 'Please Select Opening Date'; }
    if (!values.itrCompanyDueDate) { errors.itrCompanyDueDate = 'Please Select Due Date'; }

    return errors;
  };


  const onSubmit = () => {
    const fieldErrors = validate(data);

    if (!_.isEmpty(fieldErrors)) {
      setFormErrors(fieldErrors);
      return;
    }

    const due = {
      "itrIndividualOpeningDate": moment(data.itrIndividualOpeningDate).format('MMM-DD'),
      "itrIndividualDueDate": moment(data.itrIndividualDueDate).format('MMM-DD'),
      "itrCompanyOpeningDate": moment(data.itrCompanyOpeningDate).format('MMM-DD'),
      "itrCompanyDueDate": moment(data.itrCompanyDueDate).format('MMM-DD'),
    };

    if (drawerType === 'existing') {
      due._id = data._id;
      due.month = data.month;
      due.year = data.year;
      dispatch(updateItDueDateHistory(due)).then((res) => {
        if (res?.payload?.message === "Data Updated Successfully") {
          ShowMessage("success", "Due Dates Updated Successfully");
          dispatch(getAllItDueDates());
          onClose();
        }
      });
    } else if (drawerType === 'new' && rowValue?._id) {
      due._id = data._id;
      dispatch(updateItDueDate(due)).then((res) => {
        if (res?.payload?.message === "Data Updated Successfully") {
          ShowMessage("success", "Due Dates Master Updated Successfully");
          dispatch(getAllItDueDates());
          onClose();
        }
      });
    } else {
      dispatch(createItDueDate(due)).then((res) => {
        if (res?.payload?.message === "Data Created Successfully") {
          ShowMessage("success", "Due Dates Master Updated Successfully");
          dispatch(getAllItDueDates());
          onClose();
        }
      });
    }
  };

  return (
    <Drawer
      title={drawerType === "existing" ? "Update IT Due Dates" : "Create New IT Due Dates"}
      width={720} onClose={onClose} open={open}
      style={{ body: { paddingBottom: 80, } }}
      extra={
        <Space>
          {mode === 'view' ? <Button type="primary" onClick={() => setMode('edit')}>Edit</Button> : <></>}
          {mode === 'edit' ? (
            <>
              {drawerType === "existing" ? <Button type="primary" danger onClick={onDelete}>Delete</Button> : <></>}
              <Button onClick={onClose}>Cancel</Button>
              <Button onClick={onSubmit} type="primary">Submit</Button>
            </>
          ) : (
            <></>
          )}
        </Space>
      }
    >
      <ITContent mode={mode} drawerType={drawerType} data={data} formErrors={formErrors} handleChange={handleChange} />
    </Drawer>
  );
};
export default DrawerIt;

const ITContent = ({ mode, drawerType, data, formErrors, handleChange }) => <>
  <h6 className="form-label">ITR - Individual Annually</h6>
  <div className='d-flex'>
    <InputContainer
      required
      disabled={!!(mode === 'view' && drawerType === 'existing')}
      label="Opening Date"
      type="prime-date"
      id="itrIndividualOpeningDate"
      format="DD-MMM"
      value={data?.itrIndividualOpeningDate}
      error={formErrors?.itrIndividualOpeningDate}
      name="itrIndividualOpeningDate"
      picker="date"
      // eslint-disable-next-line react/jsx-no-bind
      onChange={handleChange.bind(this, "itrIndividualOpeningDate")}
      placeholder="Select Opening Date"
      containerClassName="pe-3 pb-1 col-md-6"
    />
    <InputContainer
      required
      disabled={!!(mode === 'view' && drawerType === 'existing')}
      label="Due Date"
      type="prime-date"
      id="itrIndividualDueDate"
      format="DD-MMM"
      value={data?.itrIndividualDueDate}
      error={formErrors?.itrIndividualDueDate}
      name="itrIndividualDueDate"
      picker="date"
      // eslint-disable-next-line react/jsx-no-bind
      onChange={handleChange.bind(this, "itrIndividualDueDate")}
      placeholder="Select Due Date"
      containerClassName="pe-3 pb-1 col-md-6"
    />
  </div>
  <hr />
  <h6 className="form-label">ITR - Company Annually</h6>
  <div className='d-flex'>
    <InputContainer
      required
      disabled={!!(mode === 'view' && drawerType === 'existing')}
      label="Opening Date"
      type="prime-date"
      id="itrCompanyOpeningDate"
      format="DD-MMM"
      value={data?.itrCompanyOpeningDate}
      error={formErrors?.itrCompanyOpeningDate}
      name="itrCompanyOpeningDate"
      picker="date"
      // eslint-disable-next-line react/jsx-no-bind
      onChange={handleChange.bind(this, "itrCompanyOpeningDate")}
      placeholder="Select Opening Date"
      containerClassName="pe-3 pb-1 col-md-6"
    />
    <InputContainer
      required
      disabled={!!(mode === 'view' && drawerType === 'existing')}
      label="Due Date"
      type="prime-date"
      id="itrCompanyDueDate"
      format="DD-MMM"
      value={data?.itrCompanyDueDate}
      error={formErrors?.itrCompanyDueDate}
      name="itrCompanyDueDate"
      picker="date"
      // eslint-disable-next-line react/jsx-no-bind
      onChange={handleChange.bind(this, "itrCompanyDueDate")}
      placeholder="Select Due Date"
      containerClassName="pe-3 pb-1 col-md-6"
    />
  </div>
</>