import { createSlice } from '@reduxjs/toolkit';
import { getGstTasks, getGstTask, createGstTask, updateGstTask } from '../MiddleWare/GstTask';

const initialState = {
  gstTaskData: [],
  month: '',
  year: null,
  gstTask: null,
  error: '',
  loading: false,
};

const gstTaskSlice = createSlice({
  name: 'gstTasks',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // Add reducers for additional action types here, and handle loading state as needed
    builder.addCase(getGstTasks.pending, (state) => {
      state.loading = true;
    });

    builder.addCase(getGstTasks.fulfilled, (state, action) => {
      if (action.payload) {
        state.gstTaskData = action.payload;
      }
      state.loading = false;
    });

    builder.addCase(getGstTasks.rejected, (state, action) => {
      if (action.payload) {
        state.error = action.payload.message;
        state.loading = false;
      }
    });

    // Add reducers for additional action types here, and handle loading state as needed
    builder.addCase(getGstTask.pending, () => { });

    builder.addCase(getGstTask.fulfilled, (state, action) => {
      if (action.payload) {
        state.gstTask = action.payload.GstTask;
      }
    });

    builder.addCase(getGstTask.rejected, (state, action) => {
      if (action.payload) {
        state.error = action.payload.message;
        state.loading = true;
      }
    });

    // Add reducers for additional action types here, and handle loading state as needed
    builder.addCase(createGstTask.pending, () => { });

    builder.addCase(createGstTask.fulfilled, (state, action) => {
      if (action.payload) {
        state.gstTask = action.payload.GstTask;
      }
    });

    builder.addCase(createGstTask.rejected, (state, action) => {
      if (action.payload) {
        state.error = action.payload.message;
        state.loading = true;
      }
    });
  },
});

export default gstTaskSlice.reducer;
