/* eslint-disable react/jsx-no-bind */
import React, { useState, useEffect } from 'react';
import { Button, Col, Drawer, Form, Row, Space, Upload } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import moment from 'moment';
import _ from 'lodash';
import { useDispatch } from 'react-redux';

import { Confirmation, ShowMessage } from '../../components/Utils';

import InputContainer from '../../components/InputContainer/InputContainer';
import { createUser, deleteuser, getUsers, updateUser } from '../../STORE/MiddleWare/Users';

const statusType = [
  { label: 'Active', value: 'Active' },
  { label: 'In Active', value: 'In Active' },
];

const initUser = {
  id: null,
  employeeId: '',
  name: '',
  superAdmin: '',
  dateOfBirth: null,
  userName: '',
  email: '',
  password: '',
  mobile: '',
  designation: '',
  salary: '',
  address: '',
  emergencyContactPerson: '',
  emergencyContactNumber: '',
  emergencyContactRelation: '',
  status: 'Active',
  permissions: 'view_user,edit_user,delete_user',
};
// eslint-disable-next-line react/prop-types
const DrawerUsers = ({ show, setShow, userType, rowValue }) => {
  const dispatch = useDispatch();

  const [open, setOpen] = useState(false);
  const [mode, setMode] = useState('view');
  const [formErrors, setFormErrors] = useState({});

  const [userCreate, setUserCreate] = useState({});

  const [fileList, setFileList] = useState([]);

  const handleChange = ({ fileList: newFileList }) => {
    if (newFileList.length > 1) {
      setFormErrors({ ...formErrors, photo: '' });
    }
    const newFile = newFileList?.map((newFile) => ({ ...newFile, status: 'done' }));
    setFileList(newFile);
  };

  const handleChangeUserHandler = (e) => {
    const { name, value } = e.target;
    if (value) {
      setFormErrors({ ...formErrors, [name]: '' });
    }
    setUserCreate({ ...userCreate, [name]: value });
  };

  const handleSelectChangeHandler = (nameField, valueField, valueObject) => {
    if (valueObject[valueField]) {
      setFormErrors({ ...formErrors, [nameField]: '' });
    }
    setUserCreate({ ...userCreate, [nameField]: valueObject[valueField] });
  };

  const handleToggleChangeHandler = (name, value) => {
    setUserCreate({ ...userCreate, [name]: value });
  };

  const onClose = () => {
    setOpen(false);
    setShow(false);
    setMode('view');
  };

  useEffect(() => {
    setOpen(show);
    setFormErrors({});
    setMode(userType === 'new' ? 'edit' : 'view');
    // eslint-disable-next-line react/prop-types
    if (rowValue?._id) {
      setUserCreate({ ...rowValue });
      // eslint-disable-next-line react/prop-types
      if (rowValue?.photo) {
        setFileList([
          {
            uid: '-1',
            name: "Profile Photo",
            status: 'done',
            url: `${rowValue?.photo}`,
          },
        ]);
      } else {
        setFileList([]);
      }
    } else {
      setUserCreate(initUser);
      setFileList([]);
    }
  }, [show, rowValue, userType]);

  const validate = (values) => {
    const errors = {};

    if (!values.name) {
      errors.name = 'Please Enter Full Name';
    }
    if (!values.userName) {
      errors.userName = 'Please Enter User Name';
    }
    if (!values.password) {
      errors.password = 'Please Enter Password';
    }
    return errors;
  };

  const onSubmitHandler = () => {
    const fieldErrors = validate(userCreate);

    if (!_.isEmpty(fieldErrors)) {
      setFormErrors(fieldErrors);
      return;
    }

    const formData = new FormData();

    formData.append('name', userCreate.name);
    formData.append('email', userCreate.email);
    formData.append('superAdmin', userCreate.superAdmin || false);
    formData.append('employeeId', userCreate.employeeId);
    formData.append('userName', userCreate.userName);
    formData.append('dateOfBirth', userCreate.dateOfBirth ? moment(userCreate.dateOfBirth).format('YYYY-MM-DD') : '');
    formData.append('mobile', userCreate.mobile);
    formData.append('designation', userCreate.designation);
    formData.append('salary', userCreate.salary ? userCreate.salary : 0);
    formData.append('address', userCreate.address);
    formData.append('status', userCreate.status);
    formData.append('emergencyContactPerson', userCreate.emergencyContactPerson);
    formData.append('emergencyContactNumber', userCreate.emergencyContactNumber);
    formData.append('emergencyContactRelation', userCreate.emergencyContactRelation);
    formData.append('permissions', 'view_user,edit_user,delete_user');
    if (fileList[0]?.url) {
      formData.append('photo', userCreate?.photo);
    } else {
      formData.append('photo', fileList[0]?.originFileObj || '');
    }

    if (userType === 'new') {
      formData.append('password', userCreate.password);
      dispatch(createUser(formData)).then((res) => {
        if (res?.payload?.message === 'Data Created Successfully') {
          ShowMessage('success', 'User Created Successfully');
          dispatch(getUsers());
          onClose();
        }
      });
    } else {
      formData.append('_id', userCreate._id);
      dispatch(updateUser(formData)).then((res) => {
        if (res?.payload?.message === 'Data Updated Successfully') {
          ShowMessage('success', 'User Updated Successfully');
          dispatch(getUsers());
          onClose();
        }
      });
    }
  };

  const onDeleteHandler = () => {
    Confirmation('Are you sure, you want to delete this User?', '', '', ['No', 'YES'], true).then((isConfirm) => {
      if (isConfirm) {
        const data = { _id: userCreate._id };
        dispatch(deleteuser(data)).then((res) => {
          if (res?.payload?.message === 'Data Deleted Successfully') {
            ShowMessage('success', 'User Deleted Successfully');
            dispatch(getUsers());
            onClose();
          }
        });
      }
    });
  };

  return (
    <>
      <Drawer
        title={userType === 'new' ? 'Create a New User' : 'Update User'}
        width={720}
        onClose={onClose}
        open={open}
        style={{ body: { paddingBottom: 80 } }}
        extra={
          <Space>
            {mode === 'view' && userType === 'existing' ? (
              <Button type="primary" onClick={() => setMode('edit')}>
                Edit
              </Button>
            ) : (
              <></>
            )}
            {mode === 'edit' && userType === 'existing' ? (
              <>
                <Button type="primary" danger onClick={onDeleteHandler}>
                  Delete
                </Button>
              </>
            ) : (
              <></>
            )}
            {mode === 'edit' ? (
              <>
                <Button onClick={onClose}>Cancel</Button>
                <Button onClick={onSubmitHandler} type="primary">
                  Submit
                </Button>
              </>
            ) : (
              <></>
            )}
          </Space>
        }
      >
        <Form layout="vertical">
          <Row gutter={16}>
            <Col span={12} className="mb-2">
              <InputContainer
                containerClassName={mode === 'view' && userType === 'existing' ? 'custom-disable' : ''}
                label="Empolyee ID"
                type="text"
                id="employeeId"
                name="employeeId"
                value={userCreate.employeeId}
                onChange={handleChangeUserHandler}
                placeholder="Please Enter Empolyee ID"
                disabled={!!(mode === 'view' && userType === 'existing')}
              />
            </Col>
            <Col span={12} className="mb-2">
              <InputContainer
                containerClassName={mode === 'view' && userType === 'existing' ? 'custom-disable' : ''}
                label="Name"
                type="text"
                id="name"
                name="name"
                value={userCreate.name}
                onChange={handleChangeUserHandler}
                placeholder="Please Enter Name"
                error={formErrors.name}
                disabled={!!(mode === 'view' && userType === 'existing')}
                required
              />
            </Col>
            <Col span={12} className="mb-2">
              <InputContainer
                containerClassName={mode === 'view' && userType === 'existing' ? 'custom-disable w-full ' : 'w-full'}
                label="Super Admin"
                type="toggle"
                checkedChildren="Yes"
                unCheckedChildren="No"
                id="superAdmin"
                name="superAdmin"
                value={userCreate.superAdmin}
                onChange={(value) => handleToggleChangeHandler('superAdmin', value)}
                disabled={!!(mode === 'view' && userType === 'existing')}
              />
            </Col>
            <Col span={12} className="mb-2">
              <InputContainer
                containerClassName={mode === 'view' && userType === 'existing' ? 'custom-disable' : ''}
                label="Email"
                type="text"
                id="email"
                name="email"
                value={userCreate.email}
                onChange={handleChangeUserHandler}
                placeholder="Please enter Email "
                disabled={!!(mode === 'view' && userType === 'existing')}
              />
            </Col>
            <Col span={12} className="mb-2">
              <InputContainer
                containerClassName={mode === 'view' && userType === 'existing' ? 'custom-disable' : ''}
                label="User Name"
                type="text"
                id="userName"
                name="userName"
                value={userCreate.userName}
                onChange={handleChangeUserHandler}
                placeholder="Please User Name"
                error={formErrors.userName}
                disabled={!!(mode === 'view' && userType === 'existing')}
                required
              />
            </Col>
            {userType === 'new' && (
              <Col span={12} className="mb-2">
                <InputContainer
                  containerClassName={mode === 'view' && userType === 'existing' ? 'custom-disable' : ''}
                  label="Password"
                  type="password"
                  id="password"
                  name="password"
                  value={userCreate.password}
                  onChange={handleChangeUserHandler}
                  placeholder="Please Enter Password "
                  error={formErrors.password}
                  disabled={!!(mode === 'view' && userType === 'existing')}
                  required
                />
              </Col>
            )}

            <Col span={12} className="mb-2">
              <InputContainer
                containerClassName={mode === 'view' && userType === 'existing' ? 'custom-disable' : ''}
                label="Date of Birth"
                type="prime-date"
                id="dateOfBirth"
                name="dateOfBirth"
                value={userCreate.dateOfBirth}
                onChange={handleChangeUserHandler}
                placeholder="Please Choose the Date"
                picker="date"
                format="dd/mm/yy"
                disabled={!!(mode === 'view' && userType === 'existing')}
              />
            </Col>
            <Col span={12} className="mb-2">
              <InputContainer
                containerClassName={mode === 'view' && userType === 'existing' ? 'custom-disable' : ''}
                label="Mobile No"
                type="number"
                id="mobile"
                name="mobile"
                value={userCreate.mobile}
                onChange={handleChangeUserHandler}
                placeholder="Please Enter Mobile No"
                disabled={!!(mode === 'view' && userType === 'existing')}
              />
            </Col>

            <Col span={12} className="mb-2">
              <InputContainer
                containerClassName={mode === 'view' && userType === 'existing' ? 'custom-disable' : ''}
                label="Designation"
                type="text"
                id="designation"
                name="designation"
                value={userCreate.designation}
                onChange={handleChangeUserHandler}
                placeholder="Please Enter Designation"
                disabled={!!(mode === 'view' && userType === 'existing')}
              />
            </Col>
            <Col span={12} className="mb-2">
              <InputContainer
                containerClassName={mode === 'view' && userType === 'existing' ? 'custom-disable' : ''}
                label="Salary"
                type="number"
                id="salary"
                name="salary"
                value={userCreate.salary}
                onChange={handleChangeUserHandler}
                placeholder="Please Enter Salary"
                disabled={!!(mode === 'view' && userType === 'existing')}
              />
            </Col>

            <Col span={12} className="mb-2">
              <InputContainer
                containerClassName={mode === 'view' && userType === 'existing' ? 'custom-disable' : ''}
                label="Address"
                type="textarea"
                id="address"
                name="address"
                value={userCreate.address}
                onChange={handleChangeUserHandler}
                placeholder="Please Enter Address"
                disabled={!!(mode === 'view' && userType === 'existing')}
              />
            </Col>
            <Col span={12} className="mb-2">
              <InputContainer
                containerClassName={mode === 'view' && userType === 'existing' ? 'custom-disable' : ''}
                label="Emergency Contact person"
                type="text"
                id="emergencyContactPerson"
                name="emergencyContactPerson"
                value={userCreate.emergencyContactPerson}
                onChange={handleChangeUserHandler}
                placeholder="Please Enter Emergency Contact person"
                disabled={!!(mode === 'view' && userType === 'existing')}
              />
            </Col>

            <Col span={12} className="mb-2">
              <InputContainer
                containerClassName={mode === 'view' && userType === 'existing' ? 'custom-disable' : ''}
                label="Emergency Contact Number"
                type="number"
                id="emergencyContactNumber"
                name="emergencyContactNumber"
                value={userCreate.emergencyContactNumber}
                onChange={handleChangeUserHandler}
                placeholder="Please Enter Emergency Contact Number"
                disabled={!!(mode === 'view' && userType === 'existing')}
              />
            </Col>
            <Col span={12} className="mb-2">
              <InputContainer
                containerClassName={mode === 'view' && userType === 'existing' ? 'custom-disable' : ''}
                label="Emergency Contact Relation"
                type="text"
                id="emergencyContactRelation"
                name="emergencyContactRelation"
                value={userCreate.emergencyContactRelation}
                onChange={handleChangeUserHandler}
                placeholder="Please Enter Emergency Contact Relation"
                disabled={!!(mode === 'view' && userType === 'existing')}
              />
            </Col>

            {userType === 'existing' && (
              <Col span={12} className="mb-2">
                <InputContainer
                  containerClassName={mode === 'view' && userType === 'existing' ? 'custom-disable' : ''}
                  label="Status"
                  type="select"
                  id="status"
                  name="status"
                  value={userCreate.status}
                  onChange={handleSelectChangeHandler.bind(this, 'status', 'value')}
                  error={formErrors.statusAction}
                  disabled={!!(mode === 'view' && userType === 'existing')}
                  options={statusType}
                  optionsValueKey="value"
                  optionsLabelKey="label"
                  required
                />
              </Col>
            )}
          </Row>
          <Col span={12} className="mb-2">
            <InputContainer
              containerClassName={mode === 'view' && userType === 'existing' ? 'custom-disable' : ''}
              label="Photo"
              type="empty"
              id="photo"
              name="photo"
            />
            <Upload
              customRequest={() => { }}
              listType="picture-card"
              fileList={fileList}
              onChange={handleChange}
              disabled={!!(mode === 'view' && userType === 'existing')}
            >
              {fileList.length >= 1 ? null : <Button icon={<PlusOutlined />}>Upload</Button>}
            </Upload>
          </Col>
        </Form>
      </Drawer>
    </>
  );
};
export default DrawerUsers;
