import { useSelector } from 'react-redux';

export const UseBillings = () => useSelector((state) => state.billing?.billingsData);
export const UseBilling = () => useSelector((state) => state.billing?.billing);
export const UseBillingForDashboard = () => useSelector((state) => state.billing?.billingsDataforDashboard);
export const UseBillingSearchValue = () => useSelector((state) => state.billing?.searchValue);
export const UseBillingTaxSearchValue = () => useSelector((state) => state.billing?.taxSearchValue);
export const UseBillingForTaxDashboard = () => useSelector((state) => state.billing?.billingsDataforTaxDashboard);


