import ReactDOM from "react-dom";
import "./Loader.scss";

const Loader = () => ReactDOM.createPortal(
        <div className="loader-cotnainer">
            <div className="load-wrap">
                <div className="load-2">
                    <div className="line" />
                    <div className="line" />
                    <div className="line" />
                </div>
            </div>
        </div>
        ,
        document.getElementById('loader')
    )

export default Loader;