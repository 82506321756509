import React, { useState, useEffect } from 'react';
import { Button, Drawer, Space } from 'antd';
import { useDispatch } from 'react-redux';
import moment from 'moment';
import _ from 'lodash';
import ClientDetails from './Sections/ClientDetails';
import DSCDetails from './Sections/DSCDetails';
// Css
import { createDsc, deleteDsc, getDscs, updateDsc } from '../../STORE/MiddleWare/Dscs';
import { Confirmation, ShowMessage } from '../../components/Utils';

const lastUpdateFields = ['applicationId'];
const initData = {
  id: null,
  dscType: '',
  clientId: '',
  clientName: '',
  pan: '',
  aadhaar: '',
  emailId: '',
  mobileNumber: '',
  companyName: '',
  designation: '',
  // charges: '',
  password: '',
  appliedOn: '',
  dscPortal: '',
  applicationId: '',
  validFrom: '',
  validTo: '',
  dscPaymentReceived: '',
  dscPaymentReceivedon: '',
  status: '',
  remarks: '',
  applicationIdChange: '',
};

// eslint-disable-next-line react/prop-types
const DrawerDscs = ({ show, setShow, drawerType, rowValue }) => {
  const [open, setOpen] = useState(false);
  const [mode, setMode] = useState('view');
  const [formErrors, setFormErrors] = useState({});
  const dispatch = useDispatch();

  const [data, setData] = useState({});

  useEffect(() => {
    setOpen(show);
    setFormErrors({});
    setMode(drawerType === 'new' ? 'edit' : 'view');
    if (rowValue?._id) {
      setData({ ...rowValue });
    } else {
      setData(initData);
    }
  }, [show, rowValue, drawerType]);

  const onClose = () => {
    setOpen(false);
    setShow(false);
    setMode('view');
  };

  const onDelete = () => {
    Confirmation('Are you sure, you want to delete this DSC?', '', '', ['No', 'YES'], true).then((isConfirm) => {
      if (isConfirm) {
        const dsc = { _id: data._id };
        dispatch(deleteDsc(dsc)).then((res) => {
          if (res?.payload?.message === 'Data Deleted Successfully') {
            ShowMessage('success', 'DSC Deleted Successfully');
            dispatch(getDscs());
            onClose();
          }
        });
      }
    });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === "aadhaar" && value.length > 12) {
      return;
    }
    if (value) {
      setFormErrors({ ...formErrors, [name]: '' });
    }
    const updateobject = {};
    if (lastUpdateFields.includes(name)) {
      updateobject[`${name === 'applicationId' ? 'appliedOn' : `${name}Change`}`] = moment(new Date()).format(
        'YYYY-MM-DD hh:mm A'
      );
    }
    if (name === 'validFrom') {
      updateobject.validTo = moment(value, 'DD/MM/YYYY').add(2, 'years');
    }

    updateobject[name] = value;
    setData({ ...data, ...updateobject });
  };

  const handleSelectChangeHandler = (nameField, valueField, valueObject) => {
    if (valueObject[valueField]) {
      setFormErrors({ ...formErrors, [nameField]: '' });
    }
    setData({ ...data, [nameField]: valueObject[valueField] });
  };

  const validate = (values) => {
    const errors = {};

    if (!values.dscType) {
      errors.dscType = 'Please Select DSC Type';
    }
    if (!values.clientName) {
      errors.clientName = 'Please Enter the Client Name';
    }
    return errors;
  };

  const onSubmit = () => {
    const fieldErrors = validate(data);

    if (!_.isEmpty(fieldErrors)) {
      setFormErrors(fieldErrors);
      return;
    }

    const dsc = {
      dscType: data.dscType,
      clientId: data.clientId,
      clientName: data.clientName,
      pan: data.pan,
      aadhaar: data.aadhaar,
      emailId: data.emailId,
      mobileNumber: data.mobileNumber,
      companyName: data.companyName,
      designation: data.designation,
      charges: data.charges,
      password: data.password,
      appliedOn: data.appliedOn ? moment(data.appliedOn).format('YYYY-MM-DD') : null,
      dscPortal: data.dscPortal,
      applicationId: data.applicationId,
      validFrom: data.validFrom ? moment(data.validFrom).format('YYYY-MM-DD') : null,
      validTo: data.validTo ? moment(data.validTo).format('YYYY-MM-DD') : null,
      dscPaymentReceived: data.dscPaymentReceived,
      dscPaymentReceivedon: data.dscPaymentReceivedon ? moment(data.dscPaymentReceivedon).format('YYYY-MM-DD') : null,
      status: data.status,
      remarks: data.remarks,
    };
    if (drawerType === 'existing') {
      dsc._id = data._id;
      dispatch(updateDsc(dsc)).then((res) => {
        if (res?.payload?.message === 'Data Updated Successfully') {
          ShowMessage('success', 'DCS Updated Successfully');
          dispatch(getDscs());
          onClose();
        } else {
          ShowMessage('error', 'DCS Updation Failed');
        }
      });
    } else {
      dispatch(createDsc(dsc)).then((res) => {
        if (res?.payload?.message === 'Data Created Successfully') {
          ShowMessage('success', 'DSC Created Successfully');
          dispatch(getDscs());
          onClose();
        } else {
          ShowMessage('error', 'DCS Creation Failed');
        }
      });
    }
  };

  return (
    <>
      <Drawer
        title={drawerType === 'existing' ? 'Update DSC' : 'Create a New DSC'}
        width={720}
        onClose={onClose}
        open={open}
        styles={{
          body: {
            paddingBottom: 80,
          },
        }}
        extra={
          <Space>
            {mode === 'view' ? (
              <Button type="primary" onClick={() => setMode('edit')}>
                Edit
              </Button>
            ) : (
              <></>
            )}
            {mode === 'edit' ? (
              <>
                {drawerType === 'existing' ? (
                  <Button type="primary" danger onClick={onDelete}>
                    Delete
                  </Button>
                ) : (
                  <></>
                )}
                <Button onClick={onClose}>Cancel</Button>
                <Button onClick={onSubmit} type="primary">
                  Submit
                </Button>
              </>
            ) : (
              <></>
            )}
          </Space>
        }
      >
        <div>
          <ClientDetails
            mode={mode}
            drawerType={drawerType}
            data={data}
            formErrors={formErrors}
            handleChange={handleChange}
          />
          <DSCDetails
            mode={mode}
            drawerType={drawerType}
            data={data}
            formErrors={formErrors}
            handleChange={handleChange}
            handleSelectChangeHandler={handleSelectChangeHandler}
          />
        </div>
      </Drawer>
    </>
  );
};
export default DrawerDscs;
