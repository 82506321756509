import { useEffect, useMemo, useState } from "react";
import { useDispatch } from "react-redux";

import { Helmet } from 'react-helmet-async';
import moment from "moment";

// @mui
import { Grid, Container, Typography } from '@mui/material';

// components
import { Button, Col, Row } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDollarSign, faHandHoldingDollar, faMoneyCheck, faPercent } from "@fortawesome/free-solid-svg-icons";

// sections
import { AppWidgetSummary } from '../../@dashboard/app';
import InputContainer from '../../../components/InputContainer/InputContainer';
import { formatDatetoDb } from '../../../components/Utils';
import SearchTable from '../../../components/Table/SearchTable';

// Utils
import { getClientsAutoComplete } from '../../../STORE/MiddleWare/AutoComplete';
import { UseAutoCompleteClients } from '../../../STORE/Selector/AutoComplete';
import { UseBillingForDashboard, UseBillingSearchValue } from "../../../STORE/Selector/Billing"
import { getBillingForDashboard } from '../../../STORE/MiddleWare/Billing';
import { updateSearchValue } from "../../../STORE/Slice/Billing";
// ----------------------------------------------------------------------

// -----------------

export default function BillingDashboard() {
  const dispatch = useDispatch();

  const [clientsData, setClientsData] = useState([]);
  const [data, setData] = useState({
    clientId: '',
    fromDate: '',
    toDate: ''
  })
  const [billingData, setBillingData] = useState([])

  const clients = UseAutoCompleteClients();
  const billingDashboard = UseBillingForDashboard();
  const billingSearchValue = UseBillingSearchValue();

  const handleSelectChangeHandler = (nameField, valueField, valueObject) => {
    dispatch(updateSearchValue({ ...data, [nameField]: valueObject[valueField] }));
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    dispatch(updateSearchValue({ ...data, [name]: value }));
  };

  useEffect(() => {
    setData({ ...billingSearchValue });

    if (billingSearchValue.clientId) {
      const payload = {
        clientId: billingSearchValue.clientId,
        fromDate: formatDatetoDb(billingSearchValue.fromDate),
        toDate: formatDatetoDb(billingSearchValue.toDate)
      }

      dispatch(getBillingForDashboard(payload));
    };

  }, [billingSearchValue, dispatch]);

  useEffect(() => {
    dispatch(getClientsAutoComplete());
  }, []);



  useEffect(() => {
    setBillingData(billingDashboard);
  }, [billingDashboard]);

  useEffect(() => {
    if (clients) {
      const data = [];
      clients?.forEach((element) => {
        data.push({
          label: element?.clientName,
          value: element?._id,
        });
      });
      setClientsData(data);
    }
  }, [clients]);


  const columns = [
    {
      title: 'Date',
      dataIndex: 'date',
      key: 'date',
      align: 'center',
      width: '60px',
      onCell: () => ({ className: '' }),
      render(text, record) {
        return <div>{text ? moment(record.date).utc().format('DD/MM/YYYY') : null}</div>;
      },
      fixed: 'left',
    },
    {
      title: 'Period',
      dataIndex: 'period',
      key: 'Period',
      align: 'center',
      width: '80px',
      onCell: () => ({ className: 'text-left ' }),
      render(text) {
        return <div>{text}</div>;
      },
      fixed: 'left',
      // filters: frameFilterArray(billingData, 'period'),
      // onFilter: (value, record) => record.period === value,
      // sorter: (a, b) => a.period.localeCompare(b.period),
    },
    {
      title: 'Invoice Number',
      dataIndex: 'invoiceNumber',
      key: 'invoiceNumber',
      align: 'center',
      width: '80px',
      onCell: () => ({ className: ' ' }),
      render(text) {
        return <div>{text}</div>;
      },
    },
    {
      title: 'Task',
      dataIndex: 'task',
      key: 'task',
      align: 'center',
      width: '100px',
      onCell: () => ({ className: ' ' }),
      render(text) {
        return <div>{text}</div>;
      },
    },
    {
      title: 'Particulars',
      dataIndex: 'particulars',
      key: 'particulars',
      align: 'center',
      width: '120px',
      onCell: () => ({ className: ' ' }),
      render(text) {
        return <div>{text}</div>;
      },
    },
    {
      title: 'Raised',
      dataIndex: 'type',
      key: 'type',
      align: 'center',
      width: '60px',
      onCell: () => ({ className: ' ' }),
      render(text, record) {
        return <div>{text === 'Debit' ? record.amount : null}</div>;
      },
    },
    {
      title: 'Collected',
      dataIndex: 'type',
      key: 'type',
      align: 'center',
      width: '60px',
      onCell: () => ({ className: ' ' }),
      render(text, record) {
        return <div>{text === 'Credit' ? record.amount : null}</div>;
      },
    },
    {
      title: 'Discount',
      dataIndex: 'type',
      key: 'type',
      align: 'center',
      width: '60px',
      onCell: () => ({ className: ' ' }),
      render(text, record) {
        return <div>{text === 'Discount' ? record.amount : null}</div>;
      },
    },
    {
      title: 'Payment Mode',
      dataIndex: 'modeOfPayment',
      key: 'modeOfPayment',
      align: 'center',
      width: '80px',
      onCell: () => ({ className: ' ' }),
      render(text) {
        return <div>{text}</div>;
      },
    },
    {
      title: 'Remarks',
      dataIndex: 'remarks',
      key: 'remarks',
      align: 'center',
      width: '100px',
      onCell: () => ({ className: ' ' }),
      render(text) {
        return <div>{text}</div>;
      },
    },
  ];
  const groupedTotals = useMemo(() => billingData.reduce((acc, item) => {
    const { type, amount } = item;
    const value = parseFloat(amount);

    if ((value)) {
      if (!acc[type]) {
        acc[type] = 0;
      }
      acc[type] += value;
    }
    return acc;
  }, {}), [billingData]);

  // Extract totals for specific types
  const totalBillRaised = groupedTotals?.Debit || 0;
  const totalBillCollected = groupedTotals?.Credit || 0;
  const totalDiscount = groupedTotals?.Discount || 0;
  const totalOutstanding = totalBillCollected - totalBillRaised || 0;
  return (
    <>
      <Helmet>
        <title> Dashboard | Gems Clip </title>
      </Helmet>

      <Container maxWidth="xl">
        <div className="mb-4 mt-4">
          <Row gutter={20}>
            {/* Client List as select (single select) (Through getallClientAutocomplete), From date, To Date Goes here */}
            <Col span={6}>
              <InputContainer
                // containerClassName="col-md-4"
                label=""
                type="select"
                id="clientId"
                name="clientId"
                placeholder="Please select Client"
                value={data?.clientId}
                // eslint-disable-next-line react/jsx-no-bind
                onChange={handleSelectChangeHandler.bind(this, 'clientId', 'value')}
                options={clientsData}
                optionsValueKey="value"
                optionsLabelKey="label"
              />
            </Col>
            <Col span={4}>
              <InputContainer
                label=""
                type="prime-date"
                id="fromDate"
                name="fromDate"
                value={data?.fromDate}
                onChange={handleChange}
                placeholder="Please Select Drom date"
                picker="date"
                format="dd/mm/yy"
                showButtonBar
              />
            </Col>
            <Col span={4}>
              <InputContainer
                label=""
                type="prime-date"
                id="toDate"
                name="toDate"
                value={data?.toDate}
                onChange={handleChange}
                placeholder="Please Select To Date"
                picker="date"
                format="dd/mm/yy"
                showButtonBar
              />
            </Col>
          </Row>
        </div>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6} md={2}>
            <AppWidgetSummary title="Bill Raised" total={totalBillRaised} icon={<FontAwesomeIcon icon={faDollarSign} style={{ width: '24px', height: '24px' }} />} />
          </Grid>

          <Grid item xs={12} sm={6} md={2}>
            <AppWidgetSummary title="Bill Collected" total={totalBillCollected} color="info" icon={<FontAwesomeIcon icon={faHandHoldingDollar} style={{ width: '24px', height: '24px' }} />} />
          </Grid>

          <Grid item xs={12} sm={6} md={2}>
            <AppWidgetSummary title="Discount" total={totalDiscount} color="warning" icon={<FontAwesomeIcon icon={faPercent} style={{ width: '24px', height: '24px' }} />} />
          </Grid>
          <Grid item xs={12} sm={6} md={2}>
            <AppWidgetSummary title="Outstanding" total={totalOutstanding} color="error" icon={<FontAwesomeIcon icon={faMoneyCheck} style={{ width: '24px', height: '24px' }} />} />
          </Grid>
        </Grid>
        <span>
          <div className="row mt-4 client-wrapper">
            {data?.clientId ?
              <SearchTable
                columns={columns}
                data={billingData}
              />
              : <></>}
          </div>
        </span>

      </Container>
    </>
  );
}
