import React, { useState, useEffect } from 'react';
import { Button, Drawer, Space, Collapse } from 'antd';
import { PlusOutlined, MinusOutlined } from '@ant-design/icons';
import _ from "lodash";
// Css
import moment from 'moment';
import { useDispatch } from 'react-redux';
import InputContainer from '../../../../components/InputContainer/InputContainer';
import { Confirmation, ShowMessage } from '../../../../components/Utils';
import { createGstDueDate, deleteGstDueDate, getAllGstDueDates, updateGstDueDate, updateGstDueDateHistory } from '../../../../STORE/MiddleWare/GstDueDates';

const initData = {
  id: null,
  gstr1OpeningDate: "",
  gstr1DueDate: "",
  gstr2BOpeningDate: "",
  gstr2BDueDate: "",
  gstr3BOpeningDate: "",
  gstr3BDueDate: "",
  gstr4OpeningDate: "",
  gstr4DueDate: "",
}

// eslint-disable-next-line react/prop-types
const DrawerGst = ({ show, setShow, drawerType, rowValue }) => {
  const [open, setOpen] = useState(false);
  const [mode, setMode] = useState('view');
  const [formErrors, setFormErrors] = useState({});
  const [data, setData] = useState({})

  const dispatch = useDispatch();

  useEffect(() => {
    setOpen(show);
    setFormErrors({});
    setMode(drawerType === 'new' ? "edit" : "view")
    // eslint-disable-next-line react/prop-types
    if (rowValue?._id) {
      setData({ ...rowValue });
    } else {
      setData(initData);
    }
  }, [show, rowValue, drawerType]);

  const onClose = () => {
    setOpen(false);
    setShow(false);
    setMode('view');
  };

  const onDelete = () => {

    Confirmation("Are you sure, you want to delete this DueDates?", "", "", ["No", "YES"], true).then(
      (isConfirm) => {
        if (isConfirm) {
          const dueDates = { _id: data._id };
          dispatch(deleteGstDueDate(dueDates)).then((res) => {
            if (res?.payload?.message === "Data Deleted Successfully") {
              ShowMessage("success", "Due Dates Deleted Successfully");
              dispatch(getAllGstDueDates());
              onClose();
            }
          });
        }
      }
    );
  };

  const handleChange = (name, e) => {
    const updatedData = { ...data, [name]: e.target.value }
    setData(updatedData)
  };

  const handleSelectChangeHandler = (nameField, valueField, valueObject) => {
    if (valueObject[valueField]) {
      setFormErrors({ ...formErrors, [nameField]: "" })
    }
    setData({ ...data, [nameField]: valueObject[valueField] });
  };

  const validate = (values) => {
    const errors = {};

    if (!values.gstr1OpeningDate) { errors.gstr1OpeningDate = 'Please Select Opening Date'; }
    if (!values.gstr1DueDate) { errors.gstr1DueDate = 'Please Select Due Date'; }
    if (!values.gstr2BOpeningDate) { errors.gstr2BOpeningDate = 'Please Select Opening Date'; }
    if (!values.gstr2BDueDate) { errors.gstr2BDueDate = 'Please Select Due Date'; }
    if (!values.gstr3BOpeningDate) { errors.gstr3BOpeningDate = 'Please Select Opening Date'; }
    if (!values.gstr3BDueDate) { errors.gstr3BDueDate = 'Please Select Due Date'; }
    if (!values.gstr4OpeningDate) { errors.gstr4OpeningDate = 'Please Select Opening Date'; }
    if (!values.gstr4DueDate) { errors.gstr4DueDate = 'Please Select Due Date'; }

    return errors;
  };


  const onSubmit = () => {
    const fieldErrors = validate(data);

    if (!_.isEmpty(fieldErrors)) {
      setFormErrors(fieldErrors);
      return;
    }

    const due = {
      "gstr1OpeningDate": data.gstr1OpeningDate,
      "gstr1DueDate": data.gstr1DueDate,
      "gstr2BOpeningDate": data.gstr2BOpeningDate,
      "gstr2BDueDate": data.gstr2BDueDate,
      "gstr3BOpeningDate": data.gstr3BOpeningDate,
      "gstr3BDueDate": data.gstr3BDueDate,
      "gstr4OpeningDate": data.gstr4OpeningDate,
      "gstr4DueDate": data.gstr4DueDate,
    };

    if (drawerType === 'existing') {
      due._id = data._id;
      due.month = data.month;
      due.year = data.year;
      dispatch(updateGstDueDateHistory(due)).then((res) => {
        if (res?.payload?.message === "Data Updated Successfully") {
          ShowMessage("success", "Due Dates Updated Successfully");
          dispatch(getAllGstDueDates());
          onClose();
        }
      });
    } else if (drawerType === 'new' && rowValue?._id) {
      due._id = data._id;
      dispatch(updateGstDueDate(due)).then((res) => {
        if (res?.payload?.message === "Data Updated Successfully") {
          ShowMessage("success", "Due Dates Master Updated Successfully");
          dispatch(getAllGstDueDates());
          onClose();
        }
      });
    } else {
      dispatch(createGstDueDate(due)).then((res) => {
        if (res?.payload?.message === "Data Created Successfully") {
          ShowMessage("success", "Due Dates Master Updated Successfully");
          dispatch(getAllGstDueDates());
          onClose();
        }
      });
    }
  };

  return (
    <Drawer
      title={drawerType === "existing" ? "Update GST Due Dates" : "Create New GST Due Dates"}
      width={720} onClose={onClose} open={open}
      style={{ body: { paddingBottom: 80, } }}
      extra={
        <Space>
          {mode === 'view' ? <Button type="primary" onClick={() => setMode('edit')}>Edit</Button> : <></>}
          {mode === 'edit' ? (
            <>
              {drawerType === "existing" ? <Button type="primary" danger onClick={onDelete}>Delete</Button> : <></>}
              <Button onClick={onClose}>Cancel</Button>
              <Button onClick={onSubmit} type="primary">Submit</Button>
            </>
          ) : (
            <></>
          )}
        </Space>
      }
    >
      <GSTContent mode={mode} drawerType={drawerType} data={data} formErrors={formErrors} handleSelectChangeHandler={handleSelectChangeHandler} />
    </Drawer>
  );
};
export default DrawerGst;


// Filing Options
const openingDateOption = [
  { value: '1', label: '1' },
  { value: '2', label: '2' },
  { value: '3', label: '3' },
  { value: '4', label: '4' },
  { value: '5', label: '5' },
  { value: '6', label: '6' },
  { value: '7', label: '7' },
  { value: '8', label: '8' },
  { value: '9', label: '9' },
  { value: '10', label: '10' },
  { value: '11', label: '11' },
  { value: '12', label: '12' },
  { value: '13', label: '13' },
  { value: '14', label: '14' },
  { value: '15', label: '15' },
  { value: '16', label: '16' },
  { value: '17', label: '17' },
  { value: '18', label: '18' },
  { value: '19', label: '19' },
  { value: '20', label: '20' },
  { value: '21', label: '21' },
  { value: '22', label: '22' },
  { value: '23', label: '23' },
  { value: '24', label: '24' },
  { value: '25', label: '25' },
  { value: '26', label: '26' },
  { value: '27', label: '27' },
  { value: '28', label: '28' },
  { value: '29', label: '29' },
  { value: '30', label: '30' },
  { value: '31', label: '31' },
]

const dueDateOption = [
  { value: '1', label: '1' },
  { value: '2', label: '2' },
  { value: '3', label: '3' },
  { value: '4', label: '4' },
  { value: '5', label: '5' },
  { value: '6', label: '6' },
  { value: '7', label: '7' },
  { value: '8', label: '8' },
  { value: '9', label: '9' },
  { value: '10', label: '10' },
  { value: '11', label: '11' },
  { value: '12', label: '12' },
  { value: '13', label: '13' },
  { value: '14', label: '14' },
  { value: '15', label: '15' },
  { value: '16', label: '16' },
  { value: '17', label: '17' },
  { value: '18', label: '18' },
  { value: '19', label: '19' },
  { value: '20', label: '20' },
  { value: '21', label: '21' },
  { value: '22', label: '22' },
  { value: '23', label: '23' },
  { value: '24', label: '24' },
  { value: '25', label: '25' },
  { value: '26', label: '26' },
  { value: '27', label: '27' },
  { value: '28', label: '28' },
  { value: '29', label: '29' },
  { value: '30', label: '30' },
  { value: '31', label: '31' },
]



const GSTContent = ({ mode, drawerType, data, formErrors, handleSelectChangeHandler }) => <><h6 className="form-label">GSTR-1 Monthly</h6>
  <div className='d-flex'>
    <InputContainer
      required
      disabled={!!(mode === 'view' && drawerType === 'existing')}
      label="Opening Date"
      type="select"
      id="gstr1OpeningDate"
      name="gstr1OpeningDate"
      value={data?.gstr1OpeningDate}
      error={formErrors?.gstr1OpeningDate}
      // eslint-disable-next-line react/jsx-no-bind
      onChange={handleSelectChangeHandler.bind(this, 'gstr1OpeningDate', 'value')}
      options={openingDateOption}
      optionsValueKey="value"
      optionsLabelKey="label"
      placeholder="Select the Opening Date"
      containerClassName="pe-3 pb-1 col-md-6"
    />
    <InputContainer
      required
      disabled={!!(mode === 'view' && drawerType === 'existing')}
      label="Due Date"
      type="select"
      id="gstr1DueDate"
      name="gstr1DueDate"
      value={data?.gstr1DueDate}
      error={formErrors?.gstr1DueDate}
      // eslint-disable-next-line react/jsx-no-bind
      onChange={handleSelectChangeHandler.bind(this, 'gstr1DueDate', 'value')}
      options={dueDateOption}
      optionsValueKey="value"
      optionsLabelKey="label"
      placeholder="Select the Due Date"
      containerClassName="pe-3 pb-1 col-md-6"
    />
  </div>
  <hr />
  <h6 className="form-label">GSTR-2B Monthly</h6>
  <div className='d-flex'>
    <InputContainer
      required
      disabled={!!(mode === 'view' && drawerType === 'existing')}
      label="Opening Date"
      type="select"
      id="gstr2BOpeningDate"
      name="gstr2BOpeningDate"
      value={data?.gstr2BOpeningDate}
      error={formErrors?.gstr2BOpeningDate}
      // eslint-disable-next-line react/jsx-no-bind
      onChange={handleSelectChangeHandler.bind(this, 'gstr2BOpeningDate', 'value')}
      options={openingDateOption}
      optionsValueKey="value"
      optionsLabelKey="label"
      placeholder="Select the Opening Date"
      containerClassName="pe-3 pb-1 col-md-6"
    />
    <InputContainer
      required
      disabled={!!(mode === 'view' && drawerType === 'existing')}
      label="Due Date"
      type="select"
      id="gstr2BDueDate"
      name="gstr2BDueDate"
      value={data?.gstr2BDueDate}
      error={formErrors?.gstr2BDueDate}
      // eslint-disable-next-line react/jsx-no-bind
      onChange={handleSelectChangeHandler.bind(this, 'gstr2BDueDate', 'value')}
      options={dueDateOption}
      optionsValueKey="value"
      optionsLabelKey="label"
      placeholder="Select the Due Date"
      containerClassName="pe-3 pb-1 col-md-6"
    />
  </div>
  <hr />
  <h6 className="form-label">GSTR-3B Monthly</h6>
  <div className='d-flex'>
    <InputContainer
      required
      disabled={!!(mode === 'view' && drawerType === 'existing')}
      label="Opening Date"
      type="select"
      id="gstr3BOpeningDate"
      name="gstr3BOpeningDate"
      value={data?.gstr3BOpeningDate}
      error={formErrors?.gstr3BOpeningDate}
      // eslint-disable-next-line react/jsx-no-bind
      onChange={handleSelectChangeHandler.bind(this, 'gstr3BOpeningDate', 'value')}
      options={openingDateOption}
      optionsValueKey="value"
      optionsLabelKey="label"
      placeholder="Select the Opening Date"
      containerClassName="pe-3 pb-1 col-md-6"
    />
    <InputContainer
      required
      disabled={!!(mode === 'view' && drawerType === 'existing')}
      label="Due Date"
      type="select"
      id="gstr3BDueDate"
      name="gstr3BDueDate"
      value={data?.gstr3BDueDate}
      error={formErrors?.gstr3BDueDate}
      // eslint-disable-next-line react/jsx-no-bind
      onChange={handleSelectChangeHandler.bind(this, 'gstr3BDueDate', 'value')}
      options={dueDateOption}
      optionsValueKey="value"
      optionsLabelKey="label"
      placeholder="Select the Due Date"
      containerClassName="pe-3 pb-1 col-md-6"
    />
  </div>
  <hr />
  <h6 className="form-label">GSTR-4 Quarterly</h6>
  <div className='d-flex'>
    <InputContainer
      required
      disabled={!!(mode === 'view' && drawerType === 'existing')}
      label="Opening Date"
      type="select"
      id="gstr4OpeningDate"
      name="gstr4OpeningDate"
      value={data?.gstr4OpeningDate}
      error={formErrors?.gstr4OpeningDate}
      // eslint-disable-next-line react/jsx-no-bind
      onChange={handleSelectChangeHandler.bind(this, 'gstr4OpeningDate', 'value')}
      options={openingDateOption}
      optionsValueKey="value"
      optionsLabelKey="label"
      placeholder="Select the Opening Date"
      containerClassName="pe-3 pb-1 col-md-6"
    />
    <InputContainer
      required
      disabled={!!(mode === 'view' && drawerType === 'existing')}
      label="Due Date"
      type="select"
      id="gstr4DueDate"
      name="gstr4DueDate"
      value={data?.gstr4DueDate}
      error={formErrors?.gstr4DueDate}
      // eslint-disable-next-line react/jsx-no-bind
      onChange={handleSelectChangeHandler.bind(this, 'gstr4DueDate', 'value')}
      options={dueDateOption}
      optionsValueKey="value"
      optionsLabelKey="label"
      placeholder="Select the Due Date"
      containerClassName="pe-3 pb-1 col-md-6"
    />
  </div>
</>