import InputContainer from '../../../components/InputContainer/InputContainer';

const BankAccountsDetails = ({ mode, clientType, data, formErrors, handleChange, handleSelectChangeHandler }) => (
  <>
    <div className="d-flex flex-wrap">
      <InputContainer
        label="Mode"
        type="select"
        id="mode"
        name="mode"
        value={data?.mode}
        // eslint-disable-next-line react/jsx-no-bind
        onChange={handleSelectChangeHandler.bind(this, 'mode', 'value')}
        options={modeOptions}
        optionsValueKey="value"
        optionsLabelKey="label"
        placeholder="Select Mode"
        error={formErrors?.mode}
        containerClassName={`${mode === 'view' ? 'custom-disable' : ''} pe-3 pb-1 col-md-6`}
        disabled={!!(mode === 'view' && clientType === 'existing')}
        required
      />
      <InputContainer
        label="Bank Name"
        type="text"
        id="bank"
        name="bank"
        value={data?.bank}
        onChange={handleChange}
        placeholder="Enter Bank Name"
        error={formErrors?.bank}
        containerClassName={`${mode === 'view' ? 'custom-disable' : ''} pe-3 pb-1 col-md-6`}
        disabled={!!(mode === 'view' && clientType === 'existing')}
        required
      />
      <InputContainer
        label="Branch Name"
        type="text"
        id="branch"
        name="branch"
        value={data?.branch}
        onChange={handleChange}
        placeholder="Enter Branch Name"
        error={formErrors?.branch}
        containerClassName={`${mode === 'view' ? 'custom-disable' : ''} pe-3 pb-1 col-md-6`}
        disabled={!!(mode === 'view' && clientType === 'existing')}
        required
      />
      <InputContainer
        label="IFSC Code"
        type="text"
        id="ifsc"
        name="ifsc"
        value={data?.ifsc}
        onChange={handleChange}
        placeholder="Enter IFSC Code"
        error={formErrors?.ifsc}
        containerClassName={`${mode === 'view' ? 'custom-disable' : ''} pe-3 pb-1 col-md-6`}
        disabled={!!(mode === 'view' && clientType === 'existing')}
      />
      <InputContainer
        label="Account Type"
        type="select"
        id="type"
        name="type"
        value={data?.type}
        // eslint-disable-next-line react/jsx-no-bind, react/prop-types
        onChange={handleSelectChangeHandler.bind(this, 'type', 'value')}
        options={typeOptions}
        optionsValueKey="value"
        optionsLabelKey="label"
        placeholder="Select Account Type"
        error={formErrors?.type}
        containerClassName={`${mode === 'view' ? 'custom-disable' : ''} pe-3 pb-1 col-md-6`}
        disabled={!!(mode === 'view' && clientType === 'existing')}
        required
      />
      <InputContainer
        label="Account Number"
        type="number"
        id="accountNumber"
        name="accountNumber"
        value={data?.accountNumber}
        onChange={handleChange}
        placeholder="Enter Account Number"
        error={formErrors?.accountNumber}
        containerClassName={`${mode === 'view' ? 'custom-disable' : ''} pe-3 pb-1 col-md-6`}
        disabled={!!(mode === 'view' && clientType === 'existing')}
        required
      />
    </div>
  </>
);

export default BankAccountsDetails;
const modeOptions = [
  { value: 'UPI', label: 'UPI' },
  { value: 'Bank Transfer', label: 'Bank Transfer' },
];
const typeOptions = [
  { value: 'Current', label: 'Current' },
  { value: 'Savings', label: 'Savings' },
];
