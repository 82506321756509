const urls = {
  login: {
    url: 'api/auth/signin',
  },
  userUrl: {
    url: 'api/users',
  },
  clientUrl: {
    url: 'api/clients',
  },
  updateUserPassword: {
    url: 'api/users/update-password',
  },
  panUrl: {
    url: 'api/pan',
  },
  dscUrl: {
    url: 'api/dsc',
  },
  gstDueDatesUrl: {
    url: 'api/gstDueDates',
  },
  gstDueDatesHistoryUrl: {
    url: 'api/gstDueDatesHistory',
  },
  itDueDatesUrl: {
    url: 'api/itDueDates',
  },
  itDueDatesHistoryUrl: {
    url: 'api/itDueDatesHistory',
  },
  gstTaskUrl: {
    url: 'api/gstTask',
  },
  itTaskUrl: {
    url: 'api/itTask',
  },
  billingUrl: {
    url: 'api/billing',
  },
  bankAccountsUrl: {
    url: 'api/bankAccounts',
  },
  clientsAutocomplete: {
    url: 'api/autocomplete/clients',
  },
  logUrl: {
    url: 'api/log',
  },
};

export default urls;
