import { combineReducers } from 'redux';
import { configureStore } from '@reduxjs/toolkit';
import authReducer from './Slice/Auth';
import clientsReducer from './Slice/Clients';
import usersReducer from './Slice/Users';
import utilsReducer from './Slice/Utils';
import PanReducer from './Slice/Pan';
import DscReducer from './Slice/Dsc';
import BillingReducer from './Slice/Billing';
import gstDueDatesReducer from './Slice/GstDueDates';
import gstTasksReducer from './Slice/GstTask';
import itTasksReducer from './Slice/ItTask';
import itDueDatesReducer from './Slice/ItDueDates';
import bankAccountsReducer from './Slice/BankAccounts';
import autoCompleteReducer from './Slice/AutoComplete';

const rootReducer = combineReducers({
  auth: authReducer,
  clients: clientsReducer,
  utils: utilsReducer,
  users: usersReducer,
  pan: PanReducer,
  dsc: DscReducer,
  billing: BillingReducer,
  gstDueDates: gstDueDatesReducer,
  gstTasks: gstTasksReducer,
  itTasks: itTasksReducer,
  itDueDates: itDueDatesReducer,
  bankAccounts: bankAccountsReducer,
  autocomplete: autoCompleteReducer,
});

export default configureStore({
  reducer: rootReducer,
});
