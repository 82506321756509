import InputContainer from '../../../components/InputContainer/InputContainer';

const BillingDetails = ({ mode, clientType, data, formErrors, handleChange, handleSelectChangeHandler, clients }) => (
  <>
    <div className="d-flex flex-wrap">
      <InputContainer
        label="Date"
        type="prime-date"
        id="date"
        name="date"
        value={data?.date}
        onChange={handleChange}
        placeholder="Please Select the Date"
        picker="date"
        format="dd/mm/yy"
        containerClassName={`${mode === 'view' ? 'custom-disable' : ''} pe-3 pb-1 col-md-6`}
        disabled
        required
      />
      <InputContainer
        label="Invoice Number"
        type="text"
        id="invoiceNumber"
        name="invoiceNumber"
        value={data?.invoiceNumber}
        onChange={handleChange}
        placeholder="Enter Billing Invoice Number"
        error={formErrors?.invoiceNumber}
        containerClassName={`${mode === 'view' ? 'custom-disable' : ''} pe-3 pb-1 col-md-6`}
        disabled={!!(mode === 'view' && clientType === 'existing')}
        required
      />
      <InputContainer
        label="Period"
        type="text"
        id="period"
        name="period"
        value={data?.period}
        onChange={handleChange}
        placeholder="Enter Billing Period"
        error={formErrors?.period}
        containerClassName={`${mode === 'view' ? 'custom-disable' : ''} pe-3 pb-1 col-md-6`}
        disabled={!!(mode === 'view' && clientType === 'existing')}
        required
      />
      <InputContainer
        label="Client Name"
        type="select"
        id="clientId"
        name="clientId"
        value={data?.clientId}
        // eslint-disable-next-line react/jsx-no-bind
        onChange={handleSelectChangeHandler.bind(this, 'clientId', '_id')}
        options={clients}
        optionsValueKey="_id"
        optionsLabelKey="clientName"
        placeholder="Select Client Name"
        error={formErrors?.clientId}
        containerClassName={`${mode === 'view' ? 'custom-disable' : ''} pe-3 pb-1 col-md-6`}
        disabled={!!(mode === 'view' && clientType === 'existing')}
        required
      />
      <InputContainer
        label="Task"
        type="text"
        id="task"
        name="task"
        value={data?.task}
        onChange={handleChange}
        placeholder="Enter Task"
        error={formErrors?.task}
        containerClassName={`${mode === 'view' ? 'custom-disable' : ''} pe-3 pb-1 col-md-6`}
        disabled={!!(mode === 'view' && clientType === 'existing')}
        required
      />
      <InputContainer
        label="Particulars"
        type="text"
        id="particulars"
        name="particulars"
        value={data?.particulars}
        onChange={handleChange}
        placeholder="Enter Particulars"
        error={formErrors?.particulars}
        containerClassName={`${mode === 'view' ? 'custom-disable' : ''} pe-3 pb-1 col-md-6`}
        disabled={!!(mode === 'view' && clientType === 'existing')}
      />
      <InputContainer
        label="Bill Type"
        type="select"
        id="type"
        name="type"
        value={data?.type}
        // eslint-disable-next-line react/jsx-no-bind, react/prop-types
        onChange={handleSelectChangeHandler.bind(this, 'type', 'value')}
        options={BillTypeOptions}
        optionsValueKey="value"
        optionsLabelKey="label"
        placeholder="Select Bill Type"
        error={formErrors?.type}
        containerClassName={`${mode === 'view' ? 'custom-disable' : ''} pe-3 pb-1 col-md-6`}
        disabled={!!(mode === 'view' && clientType === 'existing')}
        required
      />
      <InputContainer
        label="Amount"
        type="number"
        id="amount"
        name="amount"
        value={data?.amount}
        onChange={handleChange}
        placeholder="Enter Amount"
        error={formErrors?.amount}
        containerClassName={`${mode === 'view' ? 'custom-disable' : ''} pe-3 pb-1 col-md-6`}
        disabled={!!(mode === 'view' && clientType === 'existing')}
        required
      />
      {data?.type === 'Credit' ? (
        <>
          <InputContainer
            label="Mode of Payment"
            type="select"
            id="modeOfPayment"
            name="modeOfPayment"
            value={data?.modeOfPayment}
            // eslint-disable-next-line react/jsx-no-bind
            onChange={handleSelectChangeHandler.bind(this, 'modeOfPayment', 'value')}
            options={paymentModeOptions}
            optionsValueKey="value"
            optionsLabelKey="label"
            placeholder="Select Mode of Payment"
            error={formErrors?.modeOfPayment}
            containerClassName={`${mode === 'view' ? 'custom-disable' : ''} pe-3 pb-1 col-md-6`}
            disabled={!!(mode === 'view' && clientType === 'existing')}
            required={data?.type === 'Credit'}
          />
          <InputContainer
            label="Receipt Number"
            type="text"
            id="receiptNumber"
            name="receiptNumber"
            value={data?.receiptNumber}
            onChange={handleChange}
            placeholder="Enter Billing Receipt Number"
            error={formErrors?.receiptNumber}
            containerClassName={`${mode === 'view' ? 'custom-disable' : ''} pe-3 pb-1 col-md-6`}
            disabled={!!(mode === 'view' && clientType === 'existing')}
            required={data?.type === 'Credit'}
          />
        </>

      ) : null}
      <InputContainer
        label="Remarks"
        type="textarea"
        id="remarks"
        name="remarks"
        value={data?.remarks}
        onChange={handleChange}
        placeholder="Enter Remarks"
        containerClassName={`${mode === 'view' ? 'custom-disable' : ''} pe-3 pb-3 col-md-12`}
        disabled={!!(mode === 'view' && clientType === 'existing')}
      />
    </div>
  </>
);

export default BillingDetails;

const paymentModeOptions = [
  { value: 'Cash', label: 'Cash' },
  { value: 'UPI', label: 'UPI' },
  { value: 'Bank Transfer', label: 'Bank Transfer' },
];

const BillTypeOptions = [
  { value: 'Debit', label: 'Bill Raised' },
  { value: 'Credit', label: 'Bill Collected' },
  { value: 'Discount', label: 'Discount' },
];
