import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Helmet } from 'react-helmet-async';

import SearchTable from '../../../components/Table/SearchTable';
import DrawerGst from './Drawer/DrawerGst';

import { formatMonthYear, formatMonthYearToTime, frameFilterDate, ordinal } from '../../../components/Utils';
import { UseGstDueDatesData, UseGstDueDatesMaster } from '../../../STORE/Selector/GstDueDates';
import { getAllGstDueDates } from '../../../STORE/MiddleWare/GstDueDates';

const Gst = ({ activeTab }) => {

    const [dueData, setDueData] = useState([]);
    const [show, setShow] = useState(false);
    const [drawerType, setDrawerType] = useState("new");
    const [rowValue, setRowValue] = useState({});
    const dispatch = useDispatch();

    const GstDueDate = UseGstDueDatesData();
    const GstDueDateMaster = UseGstDueDatesMaster();

    useEffect(() => {
        if (activeTab === '1')
            dispatch(getAllGstDueDates());
    }, [activeTab, dispatch]);

    useEffect(() => {
        setDueData(GstDueDate?.gstDueDateHistory);
    }, [GstDueDate]);

    const onRowClickHandler = (e, value) => {
        setShow(true);
        setDrawerType("existing")
        setRowValue(value);
    };

    const onNewClickHandler = () => {
        setShow(true);
        setDrawerType("new")
        setRowValue(GstDueDateMaster);
    };

    const addNewPan = {
        modalId: 'showModal',
        label: 'GST Due Dates Master',
        onClick: onNewClickHandler,
    };

    const columns = [
        {
            title: 'S.No',
            dataIndex: 's.no',
            align: 'center',
            width: '70px',
            onCell: () => ({ className: 'min-width-70-px' }),
            render: (value, row, index) => (row.page - 1) * 10 + (index + 1),
        },
        {
            title: 'Month',
            dataIndex: 'month',
            align: 'center',
            width: '150px',
            onCell: () => ({ className: 'min-width-150-px' }),
            render(text, row) {
                return text ? <div>{formatMonthYear(row)}</div> : <></>;
            },
            filters: frameFilterDate(dueData),
            onFilter: (value, record) => formatMonthYear(record) === value,
            sorter: (a, b) => formatMonthYearToTime(a) - formatMonthYearToTime(b),
        },
        {
            title: 'GSTR 1',
            dataIndex: 'gstr1OpeningDate',
            key: 'gstr1OpeningDate',
            align: 'center',
            width: '150px',
            onCell: () => ({ className: ' min-width-300-px' }),
            render(text, row) {
                return text ? <div>{`${ordinal(row.gstr1OpeningDate, 10)} - ${ordinal(row.gstr1DueDate)}`}</div> : <></>;
            },
        },
        {
            title: 'GSTR-2B',
            dataIndex: 'gstr2BOpeningDate',
            key: 'gstr2BOpeningDate',
            align: 'center',
            width: '150px',
            onCell: () => ({ className: ' min-width-300-px' }),
            render(text, row) {
                return text ? <div>{`${ordinal(row.gstr2BOpeningDate)} - ${ordinal(row.gstr2BDueDate)}`}</div> : <></>;
            },
        },
        {
            title: 'GSTR-3B',
            dataIndex: 'gstr3BOpeningDate',
            key: 'gstr3BOpeningDate',
            align: 'center',
            width: '250px',
            onCell: () => ({ className: ' min-width-300-px' }),
            render(text, row) {
                return text ? <div>{`${ordinal(row.gstr3BOpeningDate)} - ${ordinal(row.gstr3BDueDate)}`}</div> : <></>;
            },
        },
        {
            title: 'GSTR-4',
            dataIndex: 'gstr4OpeningDate',
            key: 'gstr4OpeningDate',
            align: 'center',
            width: '250px',
            onCell: () => ({ className: ' min-width-300-px' }),
            render(text, row) {
                return text ? <div>{`${ordinal(row.gstr4OpeningDate)} - ${ordinal(row.gstr4DueDate)}`}</div> : <></>;
            },
        },
    ]

    return (
        <>
            <Helmet>
                <title> Gst Due Dates | Gems Clip </title>
            </Helmet>

            <SearchTable
                columns={columns}
                data={dueData}
                searchItems={['gstr1OpeningDate', 'gstr1DueDate']}
                addNewPopup={addNewPan}
                onRowClickHandler={onRowClickHandler}
            />
            <DrawerGst show={show} setShow={setShow} drawerType={drawerType} rowValue={rowValue} />
        </>
    )
}

export default Gst;