import { useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { Tabs } from 'antd';

import '../css/DueDates.scss';

import Gst from '../sections/dueDates/Tabs/Gst';
import It from '../sections/dueDates/Tabs/It';

// create a component
const DueDates = () => {
    const [activeTab, setActiveTab] = useState("1");

    const onTabChange = (activeKey) => {
        setActiveTab(activeKey)
    }

    const items = [
        {
            key: '1',
            label: 'GST Due Dates',
            children: (
                <Gst activeTab={activeTab} />
            ),
        },
        {
            key: '2',
            label: 'IT Due Dates',
            children: (
                <It activeTab={activeTab} />
            ),
        },
        {
            key: '3',
            label: 'TDS Due Dates',
            children: (<></>),
        },
        {
            key: '4',
            label: 'Firm Renewal Due Dates',
            children: (<></>),
        },
    ];

    return (
        <>
            <Helmet>
                <title> Due Dates | Gems Clip </title>
            </Helmet>
            <div className="row mt-4 due-date-wrapper">
                <Tabs defaultActiveKey="1" items={items} onChange={onTabChange} />
            </div>
        </>
    );
}

export default DueDates;

