import React from 'react';
import moment from 'moment';
import { Collapse, Col, Form, Row, Checkbox, Space } from 'antd';
import { PlusOutlined, MinusOutlined } from '@ant-design/icons';
import InputContainer from '../../../components/InputContainer/InputContainer';

const Services = ({
  mode,
  clientType,
  data,
  handleChange,
  handleSelectChangeHandler,
  handleToggleChangeHandler,
  disable,
}) => {
  const items = [
    {
      key: '1',
      label: 'GST',
      children: (
        <div>
          <Form layout="vertical">
            <Row gutter={16}>
              <Col span={12} className="mb-2">
                <InputContainer
                  containerClassName={mode === 'view' && clientType === 'existing' ? 'custom-disable' : ''}
                  label="Status"
                  type="toggle"
                  checkedChildren="Active"
                  unCheckedChildren="In Active"
                  id="serviceGstStatus"
                  name="serviceGstStatus"
                  value={data?.serviceGstStatus}
                  // eslint-disable-next-line react/jsx-no-bind
                  onChange={(value) => handleToggleChangeHandler('serviceGstStatus', value)}
                  disabled={disable}
                />
              </Col>
              <Col span={12} className="mb-2">
                <InputContainer
                  containerClassName={mode === 'view' && clientType === 'existing' ? 'custom-disable' : ''}
                  label="GST Type"
                  type="select"
                  id="serviceGstType"
                  name="serviceGstType"
                  value={data?.serviceGstType}
                  // eslint-disable-next-line react/jsx-no-bind
                  onChange={handleSelectChangeHandler.bind(this, 'serviceGstType', 'value')}
                  options={gstType}
                  optionsValueKey="value"
                  optionsLabelKey="label"
                  disabled={disable}
                />
              </Col>
              <Col span={12} className="mb-2">
                <InputContainer
                  containerClassName={mode === 'view' && clientType === 'existing' ? 'custom-disable' : ''}
                  label="Starting Month"
                  type="prime-date"
                  id="serviceGststartingMonth"
                  name="serviceGststartingMonth"
                  value={data?.serviceGststartingMonth}
                  // eslint-disable-next-line react/jsx-no-bind
                  onChange={handleChange}
                  format="MM-yy"
                  placeholder="Please Choose Starting Month"
                  view="month"
                  disabled={disable}
                />
              </Col>
              <Col span={12} className="mb-2">
                <InputContainer
                  containerClassName={mode === 'view' && clientType === 'existing' ? 'custom-disable' : ''}
                  label="Fee"
                  type="number"
                  id="serviceGstfee"
                  name="serviceGstfee"
                  value={data?.serviceGstfee}
                  onChange={handleChange}
                  placeholder="Please Enter Fee"
                  disabled={disable}
                />
              </Col>
            </Row>
          </Form>
        </div>
      ),
    },
    {
      key: '2',
      label: 'IT',
      children: (
        <div>
          <Form layout="vertical">
            <Row gutter={16}>
              <Col span={12} className="mb-2">
                <InputContainer
                  containerClassName={mode === 'view' && clientType === 'existing' ? 'custom-disable' : ''}
                  label="Status"
                  type="toggle"
                  checkedChildren="Active"
                  unCheckedChildren="In Active"
                  id="serviceItStatus"
                  name="serviceItStatus"
                  value={data?.serviceItStatus}
                  // eslint-disable-next-line react/jsx-no-bind
                  onChange={(value) => handleToggleChangeHandler('serviceItStatus', value)}
                  disabled={disable}
                />
              </Col>
              <Col span={12} className="mb-2">
                <InputContainer
                  containerClassName={mode === 'view' && clientType === 'existing' ? 'custom-disable' : ''}
                  label="Salary Case"
                  type="toggle"
                  checkedChildren="Yes"
                  unCheckedChildren="No"
                  id="serviceItSalaryCase"
                  name="serviceItSalaryCase"
                  value={data?.serviceItSalaryCase}
                  // eslint-disable-next-line react/jsx-no-bind
                  onChange={(value) => handleToggleChangeHandler('serviceItSalaryCase', value)}
                  disabled={disable}
                />
              </Col>
              <Col span={12} className="mb-2">
                <InputContainer
                  containerClassName={mode === 'view' && clientType === 'existing' ? 'custom-disable' : ''}
                  label="Starting Year"
                  type="select"
                  id="serviceItstartingYear"
                  name="serviceItstartingYear"
                  value={data?.serviceItstartingYear}
                  // eslint-disable-next-line react/jsx-no-bind
                  onChange={handleSelectChangeHandler.bind(this, 'serviceItstartingYear', 'value')}
                  options={generateServiceYearOptions(2000, 2039)}
                  optionsValueKey="value"
                  optionsLabelKey="label"
                  placeholder="Please Choose Starting Year"
                  disabled={disable}
                />
              </Col>
              <Col span={12} className="mb-2">
                <InputContainer
                  containerClassName={mode === 'view' && clientType === 'existing' ? 'custom-disable' : ''}
                  label="Fee"
                  type="number"
                  id="serviceItFee"
                  name="serviceItFee"
                  value={data?.serviceItFee}
                  onChange={handleChange}
                  placeholder="Please Enter Fee"
                  disabled={disable}
                />
              </Col>
            </Row>
          </Form>
        </div>
      ),
    },
    {
      key: '3',
      label: 'E-Way',
      children: (
        <div>
          <Form layout="vertical">
            <Row gutter={16}>
              <Col span={12} className="mb-2">
                <InputContainer
                  containerClassName={mode === 'view' && clientType === 'existing' ? 'custom-disable' : ''}
                  label="Status"
                  type="toggle"
                  checkedChildren="Active"
                  unCheckedChildren="In Active"
                  id="lpEwayStatus"
                  name="lpEwayStatus"
                  value={data?.lpEwayStatus}
                  // eslint-disable-next-line react/jsx-no-bind
                  onChange={(value) => handleToggleChangeHandler('lpEwayStatus', value)}
                  disabled={disable}
                />
              </Col>
              <Col span={12} className="mb-2">
                <InputContainer
                  containerClassName={mode === 'view' && clientType === 'existing' ? 'custom-disable' : ''}
                  label="E-Way Username"
                  type="text"
                  id="lpEwayUsername"
                  name="lpEwayUsername"
                  value={data?.lpEwayUsername}
                  onChange={handleChange}
                  placeholder="Please Enter E-Way Username"
                  disabled={disable}
                  showClipboard
                />
              </Col>
              <Col span={12} className="mb-2">
                <InputContainer
                  containerClassName={mode === 'view' && clientType === 'existing' ? 'custom-disable' : ''}
                  label="E-Way Password"
                  type="password"
                  id="lpEwayPassword"
                  name="lpEwayPassword"
                  value={data?.lpEwayPassword}
                  onChange={handleChange}
                  placeholder="Please Enter E-Way Password"
                  disabled={disable}
                  showClipboard
                  subtext={`${data?.lpEwayPasswordChange
                    ? `Last Updated on : ${moment(data?.lpEwayPasswordChange).format('YYYY-MM-DD hh:mm A')}`
                    : ''
                    }`}
                />
              </Col>
              <Col span={12} className="mb-2">
                <InputContainer
                  containerClassName={mode === 'view' && clientType === 'existing' ? 'custom-disable' : ''}
                  label="Starting Month"
                  type="prime-date"
                  id="lpEwaystartingMonth"
                  name="lpEwaystartingMonth"
                  value={data?.lpEwaystartingMonth}
                  // eslint-disable-next-line react/jsx-no-bind
                  onChange={handleChange}
                  picker="month"
                  format="MMM-YYYY"
                  placeholder="Please Choose Starting Month"
                  disabled={disable}
                />
              </Col>
              <Col span={12} className="mb-2">
                <InputContainer
                  containerClassName={mode === 'view' && clientType === 'existing' ? 'custom-disable' : ''}
                  label="Fee"
                  type="number"
                  id="lpEwayfee"
                  name="lpEwayfee"
                  value={data?.lpEwayfee}
                  onChange={handleChange}
                  placeholder="Please Enter Fee"
                  disabled={disable}
                />
              </Col>
            </Row>
          </Form>
        </div>
      ),
    },

    {
      key: '4',
      label: 'E-Invoice',
      children: (
        <div>
          <Form layout="vertical">
            <Row gutter={16}>
              <Col span={12} className="mb-2">
                <InputContainer
                  containerClassName={mode === 'view' && clientType === 'existing' ? 'custom-disable' : ''}
                  label="Status"
                  type="toggle"
                  checkedChildren="Active"
                  unCheckedChildren="In Active"
                  id="lpEInvoiceStatus"
                  name="lpEInvoiceStatus"
                  value={data?.lpEInvoiceStatus}
                  // eslint-disable-next-line react/jsx-no-bind
                  onChange={(value) => handleToggleChangeHandler('lpEInvoiceStatus', value)}
                  disabled={disable}
                />
              </Col>
              <Col span={12} className="mb-2">
                <InputContainer
                  containerClassName={mode === 'view' && clientType === 'existing' ? 'custom-disable' : ''}
                  label="User Name"
                  type="text"
                  id="lpEInvoiceusername"
                  name="lpEInvoiceusername"
                  value={data?.lpEInvoiceusername}
                  onChange={handleChange}
                  placeholder="Please Enter User Name"
                  disabled={disable}
                  showClipboard
                />
              </Col>
              <Col span={12} className="mb-2">
                <InputContainer
                  containerClassName={mode === 'view' && clientType === 'existing' ? 'custom-disable' : ''}
                  label="Password"
                  type="password"
                  id="lpEInvoicePassword"
                  name="lpEInvoicePassword"
                  value={data?.lpEInvoicePassword}
                  onChange={handleChange}
                  placeholder="Please Enter Password"
                  disabled={disable}
                  showClipboard
                  subtext={`${data?.lpEInvoicePasswordChange
                    ? `Last Updated on : ${moment(data?.lpEInvoicePasswordChange).format('YYYY-MM-DD hh:mm A')}`
                    : ''
                    }`}
                />
              </Col>
              <Col span={12} className="mb-2">
                <InputContainer
                  containerClassName={mode === 'view' && clientType === 'existing' ? 'custom-disable' : ''}
                  label="Registered Email"
                  type="text"
                  id="lpEInvoiceEmail"
                  name="lpEInvoiceEmail"
                  value={data?.lpEInvoiceEmail}
                  onChange={handleChange}
                  placeholder="Please Enter Registered Email"
                  disabled={disable}
                />
              </Col>
              <Col span={12} className="mb-2">
                <InputContainer
                  containerClassName={mode === 'view' && clientType === 'existing' ? 'custom-disable' : ''}
                  label="Registered Mobile"
                  type="number"
                  id="lpEInvoiceMobile"
                  name="lpEInvoiceMobile"
                  value={data?.lpEInvoiceMobile}
                  onChange={handleChange}
                  placeholder="Please Enter Registered Mobile"
                  disabled={disable}
                />
              </Col>
              <Col span={12} className="mb-2">
                <InputContainer
                  containerClassName={mode === 'view' && clientType === 'existing' ? 'custom-disable' : ''}
                  label="Starting Month"
                  type="prime-date"
                  id="lpEInvoicestartingMonth"
                  name="lpEInvoicestartingMonth"
                  value={data?.lpEInvoicestartingMonth}
                  // eslint-disable-next-line react/jsx-no-bind
                  onChange={handleChange}
                  picker="month"
                  format="MMM-YYYY"
                  placeholder="Please Choose Starting Month"
                  disabled={disable}
                />
              </Col>
              <Col span={12} className="mb-2">
                <InputContainer
                  containerClassName={mode === 'view' && clientType === 'existing' ? 'custom-disable' : ''}
                  label="Fee"
                  type="number"
                  id="lpEInvoicefee"
                  name="lpEInvoicefee"
                  value={data?.lpEInvoicefee}
                  onChange={handleChange}
                  placeholder="Please Enter Fee"
                  disabled={disable}
                />
              </Col>
            </Row>
          </Form>
        </div>
      ),
    },
    {
      key: '5',
      label: 'Accounts Writing',
      children: (
        <div>
          <Form layout="vertical">
            <Row gutter={16}>
              <Col span={12} className="mb-2">
                <InputContainer
                  containerClassName={mode === 'view' && clientType === 'existing' ? 'custom-disable' : ''}
                  label="Status"
                  type="toggle"
                  checkedChildren="Active"
                  unCheckedChildren="In Active"
                  id="serviceAWStatus"
                  name="serviceAWStatus"
                  value={data?.serviceAWStatus}
                  onChange={(value) => handleToggleChangeHandler('serviceAWStatus', value)}
                  disabled={disable}
                />
              </Col>
              <Col span={12} className="mb-2">
                <InputContainer
                  containerClassName={mode === 'view' && clientType === 'existing' ? 'custom-disable' : ''}
                  label="Starting Year"
                  type="select"
                  id="serviceAWstartingYear"
                  name="serviceAWstartingYear"
                  value={data?.serviceAWstartingYear}
                  // eslint-disable-next-line react/jsx-no-bind
                  onChange={handleSelectChangeHandler.bind(this, 'serviceAWstartingYear', 'value')}
                  options={serviceYearOptions}
                  optionsValueKey="value"
                  optionsLabelKey="label"
                  placeholder="Please Choose Starting Year"
                  disabled={disable}
                />
              </Col>
              <Col span={12} className="mb-2">
                <InputContainer
                  containerClassName={mode === 'view' && clientType === 'existing' ? 'custom-disable' : ''}
                  label="Fee"
                  type="number"
                  id="serviceAWFee"
                  name="serviceAWFee"
                  value={data?.serviceAWFee}
                  onChange={handleChange}
                  placeholder="Please Enter Fee"
                  disabled={disable}
                />
              </Col>
            </Row>
          </Form>
        </div>
      ),
    },
    {
      key: '6',
      label: 'TDS',
      children: (
        <div>
          <Form layout="vertical">
            <Row gutter={16}>
              <Col span={12} className="mb-2">
                <InputContainer
                  containerClassName={mode === 'view' && clientType === 'existing' ? 'custom-disable' : ''}
                  label="Status"
                  type="toggle"
                  checkedChildren="Active"
                  unCheckedChildren="In Active"
                  id="serviceDtsStatus"
                  name="serviceDtsStatus"
                  value={data?.serviceDtsStatus}
                  onChange={(value) => handleToggleChangeHandler('serviceDtsStatus', value)}
                  disabled={disable}
                />
              </Col>
              <Col span={12} className="mb-2">
                <InputContainer
                  containerClassName={mode === 'view' && clientType === 'existing' ? 'custom-disable' : ''}
                  label="Starting Month"
                  type="prime-date"
                  id="serviceDtsstartingYear"
                  name="serviceDtsstartingYear"
                  value={data?.serviceDtsstartingYear}
                  // eslint-disable-next-line react/jsx-no-bind
                  onChange={handleChange}
                  picker="month"
                  format="MMM-YYYY"
                  placeholder="Please Choose Starting Month"
                  disabled={disable}
                />
              </Col>
              <Col span={12} className="mb-2">
                <InputContainer
                  containerClassName={mode === 'view' && clientType === 'existing' ? 'custom-disable' : ''}
                  label="Fee"
                  type="number"
                  id="serviceDtsFee"
                  name="serviceDtsFee"
                  value={data?.serviceDtsFee}
                  onChange={handleChange}
                  placeholder="Please Enter Fee"
                  disabled={disable}
                />
              </Col>
            </Row>
          </Form>
        </div>
      ),
    },
    {
      key: '7',
      label: 'Firm Renewal',
      children: (
        <div>
          <Form layout="vertical">
            <Row gutter={16}>
              <Col span={12} className="mb-2">
                <InputContainer
                  containerClassName={mode === 'view' && clientType === 'existing' ? 'custom-disable' : ''}
                  label="Status"
                  type="toggle"
                  checkedChildren="Active"
                  unCheckedChildren="In Active"
                  id="serviceFirmStatus"
                  name="serviceFirmStatus"
                  value={data?.serviceFirmStatus}
                  // eslint-disable-next-line react/jsx-no-bind
                  onChange={(value) => handleToggleChangeHandler('serviceFirmStatus', value)}
                  disabled={disable}
                />
              </Col>
              <Col span={12} className="mb-2">
                <InputContainer
                  containerClassName={mode === 'view' && clientType === 'existing' ? 'custom-disable' : ''}
                  label="Starting Year"
                  type="select"
                  id="serviceFirmstartingYear"
                  name="serviceFirmstartingYear"
                  value={data?.serviceFirmstartingYear}
                  // eslint-disable-next-line react/jsx-no-bind
                  onChange={handleSelectChangeHandler.bind(this, 'serviceFirmstartingYear', 'value')}
                  options={serviceYearOptions}
                  optionsValueKey="value"
                  optionsLabelKey="label"
                  placeholder="Please Choose Starting Year"
                  disabled={disable}
                />
              </Col>
              <Col span={12} className="mb-2">
                <InputContainer
                  containerClassName={mode === 'view' && clientType === 'existing' ? 'custom-disable' : ''}
                  label="Fee"
                  type="number"
                  id="serviceFirmFee"
                  name="serviceFirmFee"
                  value={data?.serviceFirmFee}
                  onChange={handleChange}
                  placeholder="Please Enter Fee"
                  disabled={disable}
                />
              </Col>
            </Row>
          </Form>
        </div>
      ),
    },
  ];
  return (
    <Space direction="vertical">
      <Collapse
        accordion
        items={items}
        defaultActiveKey={['1', '2', '3', '4', '5', '6', '7']}
        expandIconPosition="end"
        expandIcon={({ isActive }) => (isActive ? <MinusOutlined /> : <PlusOutlined />)}
      />
    </Space>
  );
};
export default Services;

const gstType = [
  { label: 'GST (Monthly Return)', value: 'GST (Monthly Return)' },
  { label: 'GST - Composite (Quarterly Return)', value: 'GST - Composite (Quarterly Return)' },
];
const serviceYearOptions = [
  { label: '2021-2022', value: '2021-2022' },
  { label: '2022-2023', value: '2022-2023' },
  { label: '2023-2024', value: '2023-2024' },
  { label: '2024-2025', value: '2024-2025' },
  { label: '2025-2026', value: '2025-2026' },
  { label: '2026-2027', value: '2026-2027' },
  { label: '2027-2028', value: '2027-2028' },
  { label: '2028-2029', value: '2028-2029' },
  { label: '2029-2030', value: '2029-2030' },
  { label: '2030-2031', value: '2030-2031' },
  { label: '2031-2032', value: '2031-2032' },
  { label: '2032-2033', value: '2032-2033' },
  { label: '2033-2034', value: '2033-2034' },
  { label: '2034-2035', value: '2034-2035' },
  { label: '2035-2036', value: '2035-2036' },
  { label: '2036-2037', value: '2036-2037' },
  { label: '2037-2038', value: '2037-2038' },
  { label: '2038-2039', value: '2038-2039' },
  { label: '2039-2040', value: '2039-2040' },
];
const generateServiceYearOptions = (startYear, endYear) => {
  const options = [];
  // eslint-disable-next-line no-plusplus
  for (let year = startYear; year <= endYear; year++) {
    const label = `${year}-${year + 1}`;
    options.push({ label, value: label });
  }
  return options;
};