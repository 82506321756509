import { createAsyncThunk } from '@reduxjs/toolkit';

// Axios
import axios from '../../axios/index';
import urls from '../../axios/url';

// Get All Users
export const getUsers = createAsyncThunk('users/getUsers', async () => {
  try {
    const user = JSON.parse(localStorage.getItem('userInfo'));
    const headerData = { headers: { Authorization: `Bearer ${user?.token}` } };
    const response = await axios.get(`${urls.userUrl.url}`, headerData);
    return response.data;
  } catch (err) {
    return err;
  }
});

// Get All User
export const getUser = createAsyncThunk('users/getUser', async () => {
  try {
    const response = await axios.get(`${urls.userUrl.url}`);
    return response.data;
  } catch (err) {
    return err;
  }
});

// Create User
export const createUser = createAsyncThunk('users/createUser', async (arg) => {
  try {
    const header = { headers: { 'Content-Type': 'multipart/form-data' } };
    const response = await axios.post(`${urls.userUrl.url}`, arg, header);
    return response.data;
  } catch (err) {
    return err;
  }
});

// Update User
export const updateUser = createAsyncThunk('users/updateUser', async (arg) => {
  try {
    const header = { headers: { 'Content-Type': 'multipart/form-data' } };
    const response = await axios.patch(`${urls.userUrl.url}/${arg.get('_id')}`, arg, header);
    return response.data;
  } catch (err) {
    return err;
  }
});

// Delete User
export const deleteuser = createAsyncThunk('users/deleteuser', async (arg) => {
  try {
    const response = await axios.delete(`${urls.userUrl.url}/${arg._id}`);
    return response.data;
  } catch (err) {
    return err;
  }
});

// Update User Password
export const updatePassword = createAsyncThunk('users/update-password', async (arg) => {
  try {
    const response = await axios.patch(`${urls.updateUserPassword.url}/${arg._id}`, arg);
    return response.data;
  } catch (err) {
    return err;
  }
});
