import React from 'react';
import { Tabs } from 'antd';

import Gst from './WorkSummaryTabs/Gst';

const WorkSummary = ({ clientId, open }) => {

  const items = [
    {
      key: '1',
      label: 'GST',
      children: <Gst clientId={clientId} open={open} />

    },
    {
      key: '2',
      label: 'IT',
      children: <></>,
    },
    {
      key: '3',
      label: 'Accounts Writing',
      children: <></>,
    },
    {
      key: '4',
      label: 'Firm Renewal',
      children: <></>,
    },
    {
      key: '5',
      label: 'TDS',
      children: <></>,
    },
  ];

  return <Tabs defaultActiveKey="1" items={items} />
};

export default WorkSummary;
