import { createSlice } from '@reduxjs/toolkit';
import { getPans, getPan } from '../MiddleWare/Pans';

const initialState = {
  pansData: [],
  pan: null,
};

const panSlice = createSlice({
  name: 'pans',
  initialState,
  reducers: {
    setPans(state, action) {
      return {
        ...state,
        pansData: action.payload ? action.payload.company : [],
      };
    },
    updatePans(state, action) {
      const oldData = [...state.pansData];
      const newdata = oldData.filter((pan) => pan._id !== action.payload._id);
      newdata.push(action.payload);

      return {
        ...state,
        pansData: newdata || [],
      };
    },
    createPans(state, action) {
      const newdata = [...state.pansData];
      newdata.push(action.payload);

      return {
        ...state,
        pansData: newdata || [],
      };
    },
  },
  extraReducers: (builder) => {
    // Add reducers for additional action types here, and handle loading state as needed
    builder.addCase(getPans.pending, () => {});

    builder.addCase(getPans.fulfilled, (state, action) => {
      if (action.payload) {
        state.pansData = action.payload;
      }
    });

    builder.addCase(getPans.rejected, (state, action) => {
      if (action.payload) {
        state.error = action.payload.message;
        state.loading = true;
      }
    });

    // Add reducers for additional action types here, and handle loading state as needed
    builder.addCase(getPan.pending, () => {});

    builder.addCase(getPan.fulfilled, (state, action) => {
      if (action.payload) {
        state.pan = action.payload;
      }
    });

    builder.addCase(getPan.rejected, (state, action) => {
      if (action.payload) {
        state.error = action.payload.message;
        state.loading = true;
      }
    });
  },
});

export const { createPans, setPans, updatePans } = panSlice.actions;
export default panSlice.reducer;
