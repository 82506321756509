import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useDispatch } from 'react-redux';

// Utils
import { getClients } from '../STORE/MiddleWare/Clients';
import { UseClients } from '../STORE/Selector/Clients';

// Component
import { frameFilterArray } from '../components/Utils';
import SearchTable from '../components/Table/SearchTable';
import DrawerClients from '../sections/clients/DrawerClients';

// CSS
import '../css/Clients.scss';

const Clients = () => {
  const dispatch = useDispatch();
  // State
  const [clientsData, setClientsData] = useState([]);
  const [show, setShow] = useState(false);
  const [clientType, setClientType] = useState('new');
  const [rowValue, setRowValue] = useState({});

  // Custom Selector Hooks
  const clients = UseClients();
  useEffect(() => {
    dispatch(getClients());
  }, []);

  useEffect(() => {
    setClientsData(clients?.client);
  }, [clients]);

  const onRowClickHandler = (e, value) => {
    setShow(true);
    setClientType('existing');
    setRowValue(value);
  };

  const onNewClickHandler = () => {
    setShow(true);
    setClientType('new');
    setRowValue({});
  };

  const addNewClient = {
    modalId: 'showModal',
    label: 'New Client',
    onClick: onNewClickHandler,
  };

  const columns = [
    {
      title: 'S.No',
      dataIndex: 's.no',
      align: 'center',
      width: '50px',
      onCell: () => ({ className: '' }),
      render: (value, row, index) => (row.page - 1) * 10 + (index + 1),
      fixed: 'left',
    },
    {
      title: 'Client ID',
      dataIndex: 'clientId',
      key: 'clientId',
      align: 'center',
      width: '50px',
      onCell: () => ({ className: '' }),
      render(text) {
        return <div>{text}</div>;
      },
      fixed: 'left',
      filters: frameFilterArray(clientsData, "clientId"),
      onFilter: (value, record) => record.clientId === value,
      sorter: (a, b) => a.clientId.localeCompare(b.clientId),
    },
    {
      title: 'Client Name',
      dataIndex: 'clientName',
      key: 'clientName',
      align: 'center',
      width: '130px',
      onCell: () => ({ className: ' ' }),
      render(text) {
        return <div>{text}</div>;
      },
      filters: frameFilterArray(clientsData, "clientName"),
      onFilter: (value, record) => record.clientName === value,
      sorter: (a, b) => a.clientName.localeCompare(b.clientName),
    },
    {
      title: 'Client Type',
      dataIndex: 'clientType',
      key: 'clientType',
      align: 'center',
      width: '60px',
      onCell: () => ({ className: ' ' }),
      render(text) {
        return <div>{text}</div>;
      },
      filters: frameFilterArray(clientsData, "clientType"),
      onFilter: (value, record) => record.clientType === value,
      sorter: (a, b) => a.clientType.localeCompare(b.clientType),
    },
    {
      title: 'Services',
      dataIndex: 'services',
      key: 'services',
      align: 'center',
      width: '150px',
      onCell: () => ({ className: ' ' }),
      render(text, row) {
        const labels = [
          row?.serviceGstStatus && 'GST',
          row?.serviceItStatus && 'IT',
          row?.lpEwayStatus && 'E-Way',
          row?.lpEInvoiceStatus && 'E-Invoice',
          row?.serviceDtsStatus && 'TDS',
          row?.serviceFirmStatus && 'Firm Renewal',
          row?.serviceAWStatus && 'Accounts Writing',
        ].filter(Boolean);

        return <>
          {labels.map((service, index) => (
            <span key={index} className="rounded services-item-border me-2">
              {service}
            </span>
          ))}
        </>;

      },
      filters: services,
      onFilter: (value, record) => record.services.includes(value),
    },
  ];


  return (
    <>
      <Helmet>
        <title> Client's | Gems Clip </title>
      </Helmet>

      <div className="row mt-4 client-wrapper">
        <SearchTable
          columns={columns}
          data={clientsData}
          searchItems={['clientId', 'clientName']}
          addNewPopup={addNewClient}
          onRowClickHandler={onRowClickHandler}
        />
      </div>
      <DrawerClients show={show} setShow={setShow} clientType={clientType} rowValue={rowValue} />
    </>
  );
};

export default Clients;


const services = [
  {
    text: 'GST',
    value: 'GST',
  },
  {
    text: 'IT',
    value: 'IT',
  },
  {
    text: 'E-Way',
    value: 'E-Way',
  },
  {
    text: 'E-Invoice',
    value: 'E-Invoice',
  },
  {
    text: 'TDS',
    value: 'TDS',
  },
  {
    text: 'Firm Renewal',
    value: 'Firm Renewal',
  },
  {
    text: 'Accounts Writing',
    value: 'Accounts Writing',
  },
]