// component
import SvgColor from '../../../components/svg-color';

// ----------------------------------------------------------------------

const icon = (name) => <SvgColor src={`/assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1 }} />;

export const navConfig = [
  // {
  //   title: 'dashboard',
  //   path: '/dashboard',
  //   icon: icon('dashboard'),
  // },
  {
    title: 'GST Task',
    path: '/gst-task',
    icon: icon('gst'),
  },
  {
    title: 'IT Task',
    path: '/it-task',
    icon: icon('it'),
  },
  {
    title: 'Billing',
    path: '/billing',
    icon: icon('billing'),
  },
  {
    title: 'PAN',
    path: '/pan',
    icon: icon('pan'),
  },
  {
    title: 'DSC',
    path: '/dsc',
    icon: icon('dsc'),
  },
];
export const workflowNavConfig = [];
export const masterNavConfig = [
  {
    title: 'Clients',
    path: '/clients',
    icon: icon('clients'),
  },
  {
    title: 'Due Dates',
    path: '/due-dates',
    icon: icon('duedates'),
  },
  {
    title: 'Bank Accounts',
    path: '/bank-accounts',
    icon: icon('bankaccounts'),
  },
  {
    title: 'users',
    path: '/users',
    icon: icon('users'),
  },
];
