import { createAsyncThunk } from '@reduxjs/toolkit'

// Axios
import axios from "../../axios/index";
import urls from "../../axios/url";

// Get All Dscs
export const getDscs = createAsyncThunk('dscs/getDscs', async () => {
    try {
        const response = await axios.get(`${urls.dscUrl.url}`)
        return response.data
    } catch (err) {
        return err
    }
})

// Get All Dsc
export const getDsc = createAsyncThunk('dscs/getDsc', async () => {
    try {
        const response = await axios.get(`${urls.dscUrl.url}`)
        return response.data
    } catch (err) {
        return err
    }
})

// Create Dsc
export const createDsc = createAsyncThunk('dscs/createDsc', async (arg) => {
    try {
        const response = await axios.post(`${urls.dscUrl.url}`, arg);
        return response.data
    } catch (err) {
        return err
    }
})

// Update Dsc
export const updateDsc = createAsyncThunk('dscs/updateDsc', async (arg) => {
    try {
        const response = await axios.patch(`${urls.dscUrl.url}/${arg._id}`, arg)
        return response.data
    } catch (err) {
        return err
    }
})

// Delete Dsc
export const deleteDsc = createAsyncThunk('dscs/deleteDsc', async (arg) => {
    try {
        const response = await axios.delete(`${urls.dscUrl.url}/${arg._id}`, arg)
        return response.data
    } catch (err) {
        return err
    }
})