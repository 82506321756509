import { createSlice } from '@reduxjs/toolkit'
import { getDsc, getDscs } from '../MiddleWare/Dscs';

const initialState = {
    dscsData: [],
    year: null,
    dsc: null
};

const dscSlice = createSlice({
    name: 'dscs',
    initialState,
    reducers: {
        setDscs(state, action) {
            return {
                ...state,
                dscsData: action.payload ? action.payload.comdscy : [],
            };
        },
        updateDscs(state, action) {
            const oldData = [...state.dscsData];
            const newdata = oldData.filter((dsc) => dsc._id !== action.payload._id);
            newdata.push(action.payload);

            return {
                ...state,
                dscsData: newdata || [],
            };
        },
        createDscs(state, action) {
            const newdata = [...state.dscsData];
            newdata.push(action.payload);

            return {
                ...state,
                dscsData: newdata || [],
            };
        }
    },
    extraReducers: (builder) => {
        // Add reducers for additional action types here, and handle loading state as needed
        builder.addCase(getDscs.pending, () => { })

        builder.addCase(getDscs.fulfilled, (state, action) => {
            if (action.payload) {
                state.dscsData = action.payload
            }
        })

        builder.addCase(getDscs.rejected, (state, action) => {
            if (action.payload) {
                state.error = action.payload.message
                state.loading = true;
            }
        })

        // Add reducers for additional action types here, and handle loading state as needed
        builder.addCase(getDsc.pending, () => { })

        builder.addCase(getDsc.fulfilled, (state, action) => {
            if (action.payload) {
                state.dsc = action.payload
            }
        })

        builder.addCase(getDsc.rejected, (state, action) => {
            if (action.payload) {
                state.error = action.payload.message
                state.loading = true;
            }
        })
    }
})

export const { createDscs, setDscs, updateDscs } = dscSlice.actions
export default dscSlice.reducer