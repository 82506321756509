import { createSlice } from '@reduxjs/toolkit';
import { getBankAccounts, getBankAccount } from '../MiddleWare/BankAccounts';

const initialState = {
  bankAccountsData: [],
  bankAccounts: null,
};

const bankAccountsSlice = createSlice({
  name: 'bankAccounts',
  initialState,
  reducers: {
    setBankAccounts(state, action) {
      return {
        ...state,
        bankAccountsData: action.payload ? action.payload.company : [],
      };
    },
    updateBankAccounts(state, action) {
      const oldData = [...state.bankAccountsData];
      const newdata = oldData.filter((bankAccounts) => bankAccounts._id !== action.payload._id);
      newdata.push(action.payload);

      return {
        ...state,
        bankAccountsData: newdata || [],
      };
    },
    createBankAccounts(state, action) {
      const newdata = [...state.bankAccountsData];
      newdata.push(action.payload);

      return {
        ...state,
        bankAccountsData: newdata || [],
      };
    },
  },
  extraReducers: (builder) => {
    // Add reducers for additional action types here, and handle loading state as needed
    builder.addCase(getBankAccounts.pending, () => {});

    builder.addCase(getBankAccounts.fulfilled, (state, action) => {
      if (action.payload) {
        state.bankAccountsData = action.payload;
      }
    });

    builder.addCase(getBankAccounts.rejected, (state, action) => {
      if (action.payload) {
        state.error = action.payload.message;
        state.loading = true;
      }
    });

    // Add reducers for additional action types here, and handle loading state as needed
    builder.addCase(getBankAccount.pending, () => {});

    builder.addCase(getBankAccount.fulfilled, (state, action) => {
      if (action.payload) {
        state.bankAccounts = action.payload;
      }
    });

    builder.addCase(getBankAccount.rejected, (state, action) => {
      if (action.payload) {
        state.error = action.payload.message;
        state.loading = true;
      }
    });
  },
});

export const { createPans, setPans, updatePans } = bankAccountsSlice.actions;
export default bankAccountsSlice.reducer;
