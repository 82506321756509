import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { DatePicker, Button } from 'antd';
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';

import axios from '../../axios/index';
import Table from './Table';
import { ArraySearch } from '../Utils';
import InputContainer from '../InputContainer/InputContainer';

import './SearchTable.scss';

const { RangePicker } = DatePicker;

const SearchTable = (props) => {
  const navigate = useNavigate();

  // eslint-disable-next-line react/prop-types
  const { data, searchItems, addNewLink, rangeItem, listurl, addNewPopup, getData, onRowClickHandler, onRowChangeHandler } = props;
  const [tableData, setTableData] = useState([]);
  const [searchData, setSearchData] = useState('');
  const [startDate, setStartDate] = useState(moment(new Date(), 'DD-MM-YYYY').subtract(30, 'days'));
  const [endDate, setEndDate] = useState(moment(new Date(), 'DD-MM-YYYY'));

  const onChangeHandler = (e) => {
    if (e.target.value) {
      const response = ArraySearch(data, e.target.value, searchItems);
      setTableData(response);
    } else {
      setTableData(data);
    }
    setSearchData(e.target.value);
  };

  const onRangeChangeHandler = (e) => {
    if (e) {
      getSalesList(e[0], e[1]);
      setStartDate(moment(e[0], 'DD-MM-YYYY'));
      setEndDate(moment(e[1], 'DD-MM-YYYY'));
    }
  };

  const getSalesList = async (startDate, endDate) => {
    const param = `start_date=${moment(startDate).format('YYYY-MM-DD')}&end_date=${moment(endDate).format(
      'YYYY-MM-DD'
    )}`;

    await axios
      .get(`${listurl}?${param}`)
      .then((response) => response.data)
      .then((data) => {
        setTableData(data);
      });
  };

  useEffect(() => {
    if (listurl) {
      getSalesList(startDate, endDate);
    }
  }, [listurl]);

  useEffect(() => {
    if (data) {
      if (searchData) {
        const response = ArraySearch(data, searchData, searchItems);
        setTableData(response);
      } else {
        setTableData(data);
      }
    }
  }, [data]);

  const goToHandler = () => {
    if (addNewLink?.link) {
      navigate(addNewLink.link);
    }
  };

  const openPopupHandler = () => {
    addNewPopup.onClick();
  };

  useEffect(() => {
    if (tableData?.length && getData) {
      getData(tableData);
    }
  }, [getData, tableData]);

  return (
    <div className="search-table">
      <div className="form-container flex-wrap-reverse">
        <div className="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6">
          {searchItems && searchItems.length > 0 && (
            <div className="search-box">
              <InputContainer
                type="text"
                id="search"
                name="name"
                value={searchData}
                onChange={onChangeHandler}
                placeholder="Search..."
              />
            </div>
          )}
          {rangeItem && rangeItem.length > 0 && (
            <div className="date-filter">
              <RangePicker
                className="form-control"
                size="large"
                format="DD-MM-YYYY"
                onChange={onRangeChangeHandler}
                defaultValue={[startDate, endDate]}
              />
            </div>
          )}
        </div>
        <div className="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6">
          <div className="row g-3">
            <div className="col-sm-12 text-end">
              {addNewLink?.link && (
                <div>
                  <button type="button" className="btn btn-success add-btn" onClick={goToHandler}>
                    <i className="ri-add-line align-bottom me-1" />

                    {addNewLink.label}
                  </button>
                </div>
              )}
              {addNewPopup?.label && (
                <div>
                  <Button
                    type="primary"
                    shape="round"
                    size="large"
                    onClick={openPopupHandler}
                  >
                    <FontAwesomeIcon icon={faPlus} className='me-2 new-popup-icon' />
                    <span>{addNewPopup.label}</span>
                  </Button>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <Table
        {...props}
        data={tableData}
        onClick={onRowClickHandler}
        onChange={onRowChangeHandler}
        scroll={{
          x: 1300,
        }}
      />
    </div>
  );
};

export default SearchTable;
