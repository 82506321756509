import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useDispatch } from 'react-redux';

// Utils
import { getBankAccounts } from '../STORE/MiddleWare/BankAccounts';
import { UseBankAccounts } from '../STORE/Selector/BankAccounts';

// Component
import { frameFilterArray } from '../components/Utils';
import SearchTable from '../components/Table/SearchTable';

import DrawerBankAccounts from '../sections/bankAccounts/DraweBankAccounts';

// CSS
import '../css/Billings.scss';

const BankAccounts = () => {
  const dispatch = useDispatch();
  // State
  const [bankAccountsData, setBankAccountsData] = useState([]);
  const [show, setShow] = useState(false);
  const [clientsShow, setClientsShow] = useState(false);
  const [clientType, setClientType] = useState('new');
  const [rowValue, setRowValue] = useState({});

  // Custom Selector Hooks
  const bankAccounts = UseBankAccounts();

  useEffect(() => {
    dispatch(getBankAccounts());
  }, []);

  useEffect(() => {
    setBankAccountsData(bankAccounts?.bankAccounts);
  }, [bankAccounts]);

  const onRowClickHandler = (e, value) => {
    if (e.target.classList.contains('client-info')) return;
    setShow(true);
    setClientsShow(false);
    setClientType('existing');
    setRowValue(value);
  };

  const onNewClickHandler = () => {
    setShow(true);
    setClientsShow(false);
    setClientType('new');
    setRowValue({});
  };

  const addNewBankAccounts = {
    modalId: 'showModal',
    label: 'New Bank Account',
    onClick: onNewClickHandler,
  };

  const columns = [
    {
      title: 'S.No',
      dataIndex: 's.no',
      align: 'center',
      width: '20px',
      onCell: () => ({ className: '' }),
      render: (value, row, index) => (row.page - 1) * 10 + (index + 1),
    },
    {
      title: 'Mode',
      dataIndex: 'mode',
      key: 'mode',
      align: 'center',
      width: '40px',
      onCell: () => ({ className: 'text-left ' }),
      render(text) {
        return <div>{text}</div>;
      },
      filters: frameFilterArray(bankAccountsData, 'mode'),
      onFilter: (value, record) => record.mode === value,
      sorter: (a, b) => a.mode.localeCompare(b.mode),
    },
    {
      title: 'Bank Name',
      dataIndex: 'bank',
      key: 'bank',
      align: 'center',
      width: '40px',
      onCell: () => ({ className: ' client-info' }),
      render(text) {
        return <div>{text}</div>;
      },
      filters: frameFilterArray(bankAccountsData, 'bank'),
      onFilter: (value, record) => record.bank === value,
      sorter: (a, b) => a.bank.localeCompare(b.bank),
    },
    {
      title: 'Branch Name',
      dataIndex: 'branch',
      key: 'branch',
      align: 'center',
      width: '40px',
      onCell: () => ({ className: ' ' }),
      render(text) {
        return <div>{text}</div>;
      },
      filters: frameFilterArray(bankAccountsData, 'branch'),
      onFilter: (value, record) => record.branch === value,
      sorter: (a, b) => a.branch.localeCompare(b.branch),
    },
    {
      title: 'IFSC Code',
      dataIndex: 'ifsc',
      key: 'ifsc',
      align: 'center',
      width: '40px',
      onCell: () => ({ className: ' ' }),
      render(text) {
        return <div>{text}</div>;
      },
      filters: frameFilterArray(bankAccountsData, 'ifsc'),
      onFilter: (value, record) => record.ifsc === value,
      sorter: (a, b) => a.ifsc.localeCompare(b.ifsc),
    },
    {
      title: 'Account Number',
      dataIndex: 'accountNumber',
      key: 'accountNumber',
      align: 'center',
      width: '40px',
      onCell: () => ({ className: ' ' }),
      render(text) {
        return <div>{text}</div>;
      },
    },
    {
      title: 'Type',
      dataIndex: 'type',
      key: 'type',
      align: 'center',
      width: '20px',
      onCell: () => ({ className: ' ' }),
      render(text) {
        return <div>{text}</div>;
      },
      filters: frameFilterArray(bankAccountsData, 'type'),
      onFilter: (value, record) => record.type === value,
      sorter: (a, b) => a.type.localeCompare(b.type),
    },
  ];

  return (
    <>
      <Helmet>
        <title> Client's | Gems Clip </title>
      </Helmet>
      <div className="row mt-4 client-wrapper">
        <SearchTable
          columns={columns}
          data={bankAccountsData}
          searchItems={['bank', 'ifsc', 'branch', 'mode', 'type']}
          addNewPopup={addNewBankAccounts}
          onRowClickHandler={onRowClickHandler}
        />
      </div>
      <DrawerBankAccounts show={show} setShow={setShow} clientType={clientType} rowValue={rowValue} />
    </>
  );
};

export default BankAccounts;
