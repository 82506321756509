import { createAsyncThunk } from '@reduxjs/toolkit';

// Axios
import axios from '../../axios/index';
import urls from '../../axios/url';

// Get All ItTasks
export const getItTasks = createAsyncThunk('gstTask/getItTasks', async (arg) => {
  try {
    const response = await axios.get(`${urls.itTaskUrl.url}?financialYear=${arg.financialYear}`);
    return response.data;
  } catch (err) {
    return err;
  }
});

// Get All ItTask
export const getItTask = createAsyncThunk('gstTask/getItTask', async () => {
  try {
    const response = await axios.get(`${urls.itTaskUrl.url}`);
    return response.data;
  } catch (err) {
    return err;
  }
});

// Create ItTask
export const createItTask = createAsyncThunk('gstTask/createItTask', async (arg) => {
  try {
    const response = await axios.post(`${urls.itTaskUrl.url}`, arg);
    return response.data;
  } catch (err) {
    return err;
  }
});

// Update ItTask
export const updateItTask = createAsyncThunk('gstTask/updateItTask', async (arg) => {
  try {
    const response = await axios.patch(`${urls.itTaskUrl.url}/${arg._id}`, arg);
    return response.data;
  } catch (err) {
    return err;
  }
});

// Delete ItTask
export const deleteItTask = createAsyncThunk('gstTask/deleteItTask', async (arg) => {
  try {
    const response = await axios.delete(`${urls.itTaskUrl.url}/${arg._id}`, arg);
    return response.data;
  } catch (err) {
    return err;
  }
});
