import moment from 'moment';
import InputContainer from '../../../components/InputContainer/InputContainer';

const DSCDetails = ({ mode, drawerType, data, formErrors, handleSelectChangeHandler, handleChange }) => (
  <>
    <div className="mt-4 header">DSC Details</div>
    <hr />
    <div className="d-flex flex-wrap">
      <InputContainer
        required
        label="DSC Type"
        type="text"
        id="dscType"
        name="dscType"
        value={data?.dscType}
        error={formErrors?.dscType}
        onChange={handleChange}
        placeholder="Enter DSC Type"
        containerClassName={`${mode === 'view' ? 'custom-disable' : ''} pe-3 pb-1 col-md-6`}
        disabled={!!(mode === 'view' && drawerType === 'existing')}
      />
      <InputContainer
        disabled={!!(mode === 'view' && drawerType === 'existing')}
        label="DSC portal"
        type="text"
        id="dscPortal"
        name="dscPortal"
        value={data?.dscPortal}
        onChange={handleChange}
        placeholder="Enter DSC portal "
        containerClassName={`${mode === 'view' ? 'custom-disable' : ''} pe-3 pb-1 col-md-6`}
      />
      <InputContainer
        disabled={!!(mode === 'view' && drawerType === 'existing')}
        label="Application ID"
        type="text"
        id="applicationId"
        name="applicationId"
        value={data?.applicationId}
        onChange={handleChange}
        placeholder="Enter Application ID"
        containerClassName={`${mode === 'view' ? 'custom-disable' : ''} pe-3 pb-1 col-md-6`}
        subtext={`${data?.appliedOn ? `Applied on : ${moment(data?.appliedOn).format('YYYY-MM-DD hh:mm A')}` : ''}`}
      />
      <div className="col-md-6 d-flex">
        <InputContainer
          disabled={!!(mode === 'view' && drawerType === 'existing')}
          label="Valid from"
          type="prime-date"
          id="validFrom"
          name="validFrom"
          format="dd/mm/yy"
          picker="date"
          value={data?.validFrom}
          onChange={handleChange}
          placeholder="Enter Valid From"
          containerClassName={`${mode === 'view' ? 'custom-disable' : ''} pe-3 pb-1 col-md-6`}
        />
        <InputContainer
          disabled
          label="Valid To"
          type="prime-date"
          id="validTo"
          name="validTo"
          format="dd/mm/yy"
          picker="date"
          value={data?.validTo}
          onChange={handleChange}
          placeholder="Enter Valid To"
          disabledDate={(d) =>
            d &&
            (d < moment(data?.validFrom, 'DD/MM/YYYY') || d > moment(data?.validFrom, 'DD/MM/YYYY').add(2, 'years'))
          }
          containerClassName={`${mode === 'view' ? 'custom-disable' : ''} pe-3 pb-1 col-md-6`}
        />
      </div>
      <InputContainer
        disabled={!!(mode === 'view' && drawerType === 'existing')}
        label="DSC Payment Received"
        type="text"
        id="dscPaymentReceived"
        name="dscPaymentReceived"
        value={data?.dscPaymentReceived}
        onChange={handleChange}
        placeholder="Enter DSC Payment Received"
        containerClassName={`${mode === 'view' ? 'custom-disable' : ''} pe-3 pb-1 col-md-6`}
      />
      <InputContainer
        disabled={!!(mode === 'view' && drawerType === 'existing')}
        label="DSC Payment Received On"
        type="prime-date"
        id="dscPaymentReceivedon"
        name="dscPaymentReceivedon"
        format="dd/mm/yy"
        picker="date"
        value={data?.dscPaymentReceivedon}
        onChange={handleChange}
        placeholder="Enter DSC Payment Received On"
        containerClassName={`${mode === 'view' ? 'custom-disable' : ''} pe-3 pb-1 col-md-6`}
      />
      <InputContainer
        disabled={!!(mode === 'view' && drawerType === 'existing')}
        label=" Password"
        type="password"
        id="password"
        name="password"
        value={data?.password}
        onChange={handleChange}
        placeholder="Enter Password"
        containerClassName={`${mode === 'view' ? 'custom-disable' : ''} pe-3 pb-1 col-md-6`}
      />
      <InputContainer
        disabled={!!(mode === 'view' && drawerType === 'existing')}
        label="Status"
        type="select"
        id="status"
        name="status"
        value={data?.status}
        error={formErrors?.status}
        // eslint-disable-next-line react/jsx-no-bind
        onChange={handleSelectChangeHandler.bind(this, 'status', 'value')}
        options={statusOptions}
        optionsValueKey="value"
        optionsLabelKey="label"
        placeholder="Select the Status"
        containerClassName={`${mode === 'view' ? 'custom-disable' : ''} pe-3 pb-1 col-md-6`}
      />
      <InputContainer
        disabled={!!(mode === 'view' && drawerType === 'existing')}
        label="Remarks"
        type="textarea"
        id="remarks"
        name="remarks"
        value={data?.remarks}
        onChange={handleChange}
        placeholder="Enter Remarks"
        containerClassName="pe-3 pb-1 col-md-6"
      />
    </div>
  </>
);
export default DSCDetails;

// Status Options
const statusOptions = [
  { value: 'Pending', label: 'Pending' },
  { value: 'Completed', label: 'Completed' },
  { value: 'Downloaded', label: 'Downloaded' },
  { value: 'Expired', label: 'Expired' },
];
