import React, { useState, useEffect } from 'react';
import { Button, Drawer, Space } from 'antd';
import { useDispatch } from 'react-redux';
import _ from 'lodash';

import { Confirmation, ShowMessage } from '../../components/Utils';
import BankAccountsDetails from './Sections/BankAccountsDetails';
import {
  createBankAccounts,
  deleteBankAccounts,
  getBankAccounts,
  updateBankAccounts,
} from '../../STORE/MiddleWare/BankAccounts';

// eslint-disable-next-line react/prop-types
const DrawerBankAccounts = ({ show, setShow, drawerType, clientType, rowValue }) => {
  const [open, setOpen] = useState(false);
  const [mode, setMode] = useState('view');
  const [formErrors, setFormErrors] = useState({});
  const dispatch = useDispatch();

  const [data, setData] = useState({});

  const initData = {
    mode: '',
    bank: '',
    branch: '',
    ifsc: '',
    type: '',
    accountNumber: '',
  };

  useEffect(() => {
    setOpen(show);
    setFormErrors({});
    setMode(clientType === 'new' ? 'edit' : 'view');
    if (rowValue?._id) {
      setData({ ...rowValue });
    } else {
      setData(initData);
    }
  }, [show, rowValue, drawerType]);

  const onClose = () => {
    setOpen(false);
    setShow(false);
    setMode('view');
  };

  const onDelete = () => {
    Confirmation('Are you sure, you want to delete this Bank Accounts?', '', '', ['No', 'YES'], true).then(
      (isConfirm) => {
        if (isConfirm) {
          const bankAccounts = { _id: data._id };
          dispatch(deleteBankAccounts(bankAccounts)).then((res) => {
            if (res?.payload?.message === 'Data Deleted Successfully') {
              ShowMessage('success', 'Bank Accounts Deleted Successfully');
              dispatch(getBankAccounts());
              onClose();
            }
          });
        }
      }
    );
  };

  const handleChange = (e) => {
    const { name, value } = e.target;

    if (value) {
      setFormErrors({ ...formErrors, [name]: '' });
    }

    setData({ ...data, [name]: value });
  };

  const handleSelectChangeHandler = (nameField, valueField, valueObject) => {
    if (valueObject[valueField]) {
      setFormErrors({ ...formErrors, [nameField]: '' });
    }

    setData({ ...data, [nameField]: valueObject[valueField] });
  };
  const validate = (values) => {
    const errors = {};

    if (!values.mode) {
      errors.mode = 'Please Select Mode of Payment';
    }
    if (!values.bank) {
      errors.bank = 'Please Enter Bank Name';
    }
    if (!values.branch) {
      errors.branch = 'Please Enter Branch Name';
    }
    if (!values.ifsc) {
      errors.ifsc = 'Please Enter IFSC Code';
    }
    if (!values.type) {
      errors.type = 'Please Select Account Type';
    }
    if (!values.accountNumber) {
      errors.accountNumber = 'Please Enter Account Number';
    }
    return errors;
  };

  const onSubmit = () => {
    const fieldErrors = validate(data);
    if (!_.isEmpty(fieldErrors)) {
      setFormErrors(fieldErrors);
      return;
    }
    const bankAccounts = {
      mode: data.mode,
      bank: data.bank,
      branch: data.branch,
      ifsc: data.ifsc,
      type: data.type,
      accountNumber: data.accountNumber,
    };

    if (clientType === 'existing') {
      bankAccounts._id = data._id;
      dispatch(updateBankAccounts(bankAccounts)).then((res) => {
        if (res?.payload?.message === 'Data Updated Successfully') {
          ShowMessage('success', 'Bank Accounts Updated Successfully');
          dispatch(getBankAccounts());
          onClose();
        } else {
          ShowMessage('error', res?.payload?.response?.data?.validation?.body?.message || 'Something went wrong');
        }
      });
    } else {
      dispatch(createBankAccounts(bankAccounts)).then((res) => {
        if (res?.payload?.message === 'Data Created Successfully') {
          ShowMessage('success', 'Bank Accounts Created Successfully');
          dispatch(getBankAccounts());
          onClose();
        } else {
          ShowMessage('error', res?.payload?.response?.data?.validation?.body?.message || 'Something went wrong');
        }
      });
    }
  };

  return (
    <Drawer
      title={drawerType === 'existing' ? 'Update Billing' : 'Create a New Billing'}
      width={720}
      onClose={onClose}
      open={open}
      style={{ body: { paddingBottom: 80 } }}
      extra={
        <Space>
          {mode === 'view' ? (
            <Button type="primary" onClick={() => setMode('edit')}>
              Edit
            </Button>
          ) : (
            <></>
          )}
          {mode === 'edit' ? (
            <>
              {clientType === 'existing' ? (
                <Button type="primary" danger onClick={onDelete}>
                  Delete
                </Button>
              ) : (
                <></>
              )}
              <Button onClick={onClose}>Cancel</Button>
              <Button onClick={onSubmit} type="primary">
                Submit
              </Button>
            </>
          ) : (
            <></>
          )}
        </Space>
      }
    >
      <div>
        <BankAccountsDetails
          mode={mode}
          clientType={clientType}
          data={data}
          formErrors={formErrors}
          handleChange={handleChange}
          handleSelectChangeHandler={handleSelectChangeHandler}
        />
      </div>
    </Drawer>
  );
};
export default DrawerBankAccounts;
