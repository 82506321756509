import { Navigate, useRoutes } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

// Utils
import { authLogin } from './STORE/Slice/Auth';

// layouts
import DashboardLayout from './layouts/dashboard';
import SimpleLayout from './layouts/simple';
//
import LoginPage from './pages/LoginPage';
import Page404 from './pages/Page404';

// Custom Pages
import Clients from './pages/Clients';
import Users from './pages/Users';
import Pan from './pages/Pan';
import Dsc from './pages/Dsc';
import GstTask from './pages/GstTask';
import DueDates from './pages/DueDates';
import ItTask from './pages/ItTask';
import Billing from './pages/Billing';
import BankAccounts from './pages/BankAccounts';
// ----------------------------------------------------------------------

export default function Router() {
  const dispatch = useDispatch();
  const { token } = useSelector((state) => state.auth);
  let userToken = token;

  if (token == null || token === undefined) {
    const user = JSON.parse(localStorage.getItem('userInfo'));
    if (user?.token) {
      userToken = user.token;
      dispatch(authLogin(user));
    }
  }

  const routes = useRoutes([
    {
      path: '/',
      element: userToken && userToken !== '' ? <DashboardLayout /> : <Navigate to="/login" />,
      children: [
        { element: <Navigate to="/gst-task" />, index: true },
        { path: 'gst-task', element: <GstTask /> },
        { path: 'it-task', element: <ItTask /> },
        { path: 'billing', element: <Billing /> },
        { path: 'pan', element: <Pan /> },
        { path: 'dsc', element: <Dsc /> },
        { path: 'clients', element: <Clients /> },
        { path: 'due-dates', element: <DueDates /> },
        { path: 'bank-accounts', element: <BankAccounts /> },
        { path: 'users', element: <Users /> },
      ],
    },
    {
      path: 'login',
      element: <LoginPage />,
    },
    {
      element: <SimpleLayout />,
      children: [
        { element: <Navigate to="/login" />, index: true },
        { path: '404', element: <Page404 /> },
        { path: '*', element: <Navigate to="/404" /> },
      ],
    },
    {
      path: '*',
      element: <Navigate to="/404" replace />,
    },
  ]);

  return routes;
}
