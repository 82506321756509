/* eslint-disable react/jsx-no-bind */
import React, { useState, useEffect } from 'react';
import { Button, Col, Drawer, Form, Row, Space } from 'antd';
import _, { values } from 'lodash';
import { useDispatch } from 'react-redux';

import { ShowMessage } from '../../components/Utils';

import InputContainer from '../../components/InputContainer/InputContainer';
import { getUsers, updatePassword } from '../../STORE/MiddleWare/Users';

const UpdatePasswordDrawer = ({ show, setShow, userId }) => {
  const dispatch = useDispatch();

  const [open, setOpen] = useState(false);
  const [userPassword, setUserPassword] = useState({ password: '', confirmPassword: '' });
  const [formErrors, setFormErrors] = useState({});

  const handleChangePasswordHandler = (e) => {
    const { name, value } = e.target;
    if (value) {
      setFormErrors({ ...formErrors, [name]: '' });
    }
    setUserPassword({ ...userPassword, [name]: value });
  };

  const validate = (values) => {
    const errors = {};

    if (!values.password) {
      errors.password = 'Please Enter Password';
    } else if (userPassword.confirmPassword && values.password !== userPassword.confirmPassword) {
      errors.confirmPassword = 'Password and Confirm Password does not match.';
    }

    if (!values.confirmPassword) {
      errors.confirmPassword = 'Please enter Confirm Password.';
    } else if (userPassword.password && values.confirmPassword !== userPassword.password) {
      errors.confirmPassword = 'Password and Confirm Password does not match.';
    }
    return errors;
  };

  const onClose = () => {
    setOpen(false);
    setShow(false);
  };

  useEffect(() => {
    setOpen(show);
    setFormErrors({});
  }, [show]);

  const onSubmitHandler = () => {
    const fieldErrors = validate(userPassword);

    if (!_.isEmpty(fieldErrors)) {
      setFormErrors(fieldErrors);
      return;
    }

    const data = { _id: userId, password: userPassword.password };
    dispatch(updatePassword(data)).then((res) => {
      if (res?.payload?.message === 'Data Updated Successfully') {
        ShowMessage('success', 'User Password Updated Successfully');
        dispatch(getUsers());
        onClose();
      }
    });
  };

  return (
    <>
      <Drawer
        title={'Update Password'}
        width={480}
        onClose={onClose}
        open={open}
        style={{ body: { paddingBottom: 80, } }}
        extra={
          <Space>
            <Button type="secondary" onClick={onClose}>
              Cancel
            </Button>
            <Button onClick={onSubmitHandler} type="primary">
              Submit
            </Button>
          </Space>
        }
      >
        <Form layout="vertical">
          <Row gutter={16}>
            <Col span={24} className="mb-2">
              <InputContainer
                label="Password"
                type="password"
                id="password"
                name="password"
                value={userPassword.password}
                onChange={handleChangePasswordHandler}
                placeholder="Please Enter Password"
                error={formErrors.password}
                required
              />
            </Col>
            <Col span={24} className="mb-2">
              <InputContainer
                label="Confirm Password"
                type="password"
                id="confirmPassword"
                name="confirmPassword"
                value={userPassword.confirmPassword}
                onChange={handleChangePasswordHandler}
                placeholder="Please Enter Confirm Password "
                error={formErrors.confirmPassword}
                required
              />
            </Col>
          </Row>
        </Form>
      </Drawer>
    </>
  );
};
export default UpdatePasswordDrawer;
