import { useState, useEffect } from 'react';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
// @mui
import { Stack } from '@mui/material';
import { LoadingButton } from '@mui/lab';

// components
import { ShowMessage } from '../../../components/Utils';
import InputContainer from '../../../components/InputContainer/InputContainer';

// Redux
import { authLogin } from '../../../STORE/Slice/Auth';
import { loaderHide, loaderShow } from '../../../STORE/Slice/Utils';
import { setClients } from '../../../STORE/Action/Clients';

// API
import axios from '../../../axios/index';
import urls from '../../../axios/url';


// ----------------------------------------------------------------------

export default function LoginForm() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [userName, setUsername] = useState("");
  const [password, setPassword] = useState("");

  const UsernameChangeHandler = (ev) => {
    setUsername(ev.target.value);
  }

  const PasswordChangeHandler = (ev) => {
    setPassword(ev.target.value);
  }

  const handleClick = () => {
    if (!userName) {
      ShowMessage("error", "Please Enter Your Username")
      return;
    }

    if (!password) {
      ShowMessage("error", "Please Enter Your Password")
      return;
    }

    if (userName && password) {
      dispatch(loaderShow());
      axios.post(urls.login.url, { userName, password }).then((response) => {
        if (response.data.token) {
          localStorage.setItem("userInfo", JSON.stringify(response.data));
          dispatch(authLogin(response.data));
          navigate('/gst-task', { replace: true });
          dispatch(loaderHide());
        } else {
          ShowMessage("error", "Invalid Credentials")
          dispatch(loaderHide());
        }
      })
        .catch((err) => {
          ShowMessage("error", err?.response?.data?.errors?.message || "Something Went Wrong")
          dispatch(loaderHide());
        });
    }
  };

  useEffect(() => {
    dispatch(loaderShow());
    const userInfo = JSON.parse(localStorage.getItem("userInfo"));
    if (userInfo?.token) {
      dispatch(authLogin(userInfo));
      navigate('/gst-task', { replace: true });
      dispatch(loaderHide());
    } else {
      dispatch(loaderHide());
    }
  }, [])

  return (
    <>
      <Stack spacing={3} sx={{ my: 2 }}>
        <InputContainer
          label="Username"
          type="text"
          id="userName"
          value={userName}
          name="userName"
          onChange={UsernameChangeHandler}
          placeholder="Enter Username Address"
          containerClassName="pb-2"
          labelClassName="text-1rem"
          className="height-3rem"
        />
        <InputContainer
          label="Password"
          type="password"
          id="password"
          value={password}
          name="password"
          onChange={PasswordChangeHandler}
          placeholder="Enter Password"
          containerClassName="pb-2"
          labelClassName="text-1rem"
          className="height-3rem"
        />
      </Stack>
      <LoadingButton sx={{ my: 2 }} fullWidth size="large" type="submit" variant="contained" onClick={handleClick}>
        Sign In
      </LoadingButton>
    </>
  );
}
