import { createAsyncThunk } from '@reduxjs/toolkit';

// Axios
import axios from '../../axios/index';
import urls from '../../axios/url';

// Get All BankAccounts
export const getBankAccounts = createAsyncThunk('bankAccounts/getBankAccounts', async (arg) => {
  try {
    const response = await axios.get(`${urls.bankAccountsUrl.url}`, arg);
    return response.data;
  } catch (err) {
    return err;
  }
});

// Get All Bank Account
export const getBankAccount = createAsyncThunk('bankAccounts/getBankAccount', async () => {
  try {
    const response = await axios.get(`${urls.bankAccountsUrl.url}`);
    return response.data;
  } catch (err) {
    return err;
  }
});

// Create Bank Accounts
export const createBankAccounts = createAsyncThunk('bankAccounts/createBankAccounts', async (arg) => {
  try {
    const response = await axios.post(`${urls.bankAccountsUrl.url}`, arg);
    return response.data;
  } catch (err) {
    return err;
  }
});

// Update Bank Accounts
export const updateBankAccounts = createAsyncThunk('bankAccounts/updateBankAccounts', async (arg) => {
  try {
    const response = await axios.patch(`${urls.bankAccountsUrl.url}/${arg._id}`, arg);
    return response.data;
  } catch (err) {
    return err;
  }
});

// Delete Bank Accounts
export const deleteBankAccounts = createAsyncThunk('bankAccounts/deleteBankAccounts', async (arg) => {
  try {
    const response = await axios.delete(`${urls.bankAccountsUrl.url}/${arg._id}`, arg);
    return response.data;
  } catch (err) {
    return err;
  }
});
