import { createSlice } from '@reduxjs/toolkit';
import { getClientsAutoComplete } from '../MiddleWare/AutoComplete';

const initialState = {
    clients: [],
};

const autoCompleteSlice = createSlice({
    name: 'pans',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        // Add reducers for additional action types here, and handle loading state as needed
        builder.addCase(getClientsAutoComplete.pending, () => { });

        builder.addCase(getClientsAutoComplete.fulfilled, (state, action) => {
            if (action.payload) {
                state.clients = action.payload.client;
            }
        });

        builder.addCase(getClientsAutoComplete.rejected, (state, action) => {
            if (action.payload) {
                state.error = action.payload.message;
                state.loading = true;
            }
        });
    },
});

export const { createPans, setPans, updatePans } = autoCompleteSlice.actions;
export default autoCompleteSlice.reducer;
