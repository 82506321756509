import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Helmet } from 'react-helmet-async';

import SearchTable from '../../../components/Table/SearchTable';
import DrawerIt from './Drawer/DrawerIt';

import { frameFilterArray } from '../../../components/Utils';
import { UseItDueDatesData, UseItDueDatesMaster } from '../../../STORE/Selector/ItDueDates';
import { getAllItDueDates } from '../../../STORE/MiddleWare/ItDueDates';

const It = ({ activeTab }) => {

    const [dueData, setDueData] = useState([]);
    const [show, setShow] = useState(false);
    const [drawerType, setDrawerType] = useState("new");
    const [rowValue, setRowValue] = useState({});
    const dispatch = useDispatch();

    const ItDueDate = UseItDueDatesData();
    const ItDueDateMaster = UseItDueDatesMaster();

    useEffect(() => {
        if (activeTab === '2')
            dispatch(getAllItDueDates());
    }, [activeTab, dispatch]);

    useEffect(() => {
        setDueData(ItDueDate?.itDueDateHistory);
    }, [ItDueDate]);

    const onRowClickHandler = (e, value) => {
        setShow(true);
        setDrawerType("existing")
        setRowValue(value);
    };

    const onNewClickHandler = () => {
        setShow(true);
        setDrawerType("new")
        setRowValue(ItDueDateMaster);
    };

    const addNewPan = {
        modalId: 'showModal',
        label: 'IT Due Dates Master',
        onClick: onNewClickHandler,
    };

    const columns = [
        {
            title: 'S.No',
            dataIndex: 's.no',
            align: 'center',
            width: '70px',
            onCell: () => ({ className: 'min-width-70-px' }),
            render: (value, row, index) => (row.page - 1) * 10 + (index + 1),
        },
        {
            title: 'Financial Year',
            dataIndex: 'financialYear',
            align: 'center',
            width: '150px',
            onCell: () => ({ className: 'min-width-150-px' }),
            render(text) {
                return text ? <div>{text}</div> : <></>;
            },
            filters: frameFilterArray(dueData, 'financialYear'),
            onFilter: (value, record) => record.financialYear === value,
            sorter: (a, b) => a.financialYear.localeCompare(b.financialYear),
        },
        {
            title: 'ITR - Individual',
            dataIndex: 'itrIndividualOpeningDate',
            key: 'itrIndividualOpeningDate',
            align: 'center',
            width: '250px',
            onCell: () => ({ className: ' min-width-300-px' }),
            render(text, row) {
                return text ? <div>{`${row.itrIndividualOpeningDate} - ${row.itrIndividualDueDate}`}</div> : <></>;
            },
        },
        {
            title: 'ITR - Company',
            dataIndex: 'itrCompanyOpeningDate',
            key: 'itrCompanyOpeningDate',
            align: 'center',
            width: '250px',
            onCell: () => ({ className: ' min-width-300-px' }),
            render(text, row) {
                return text ? <div>{`${row.itrCompanyOpeningDate} - ${row.itrCompanyDueDate}`}</div> : <></>;
            },
        },
    ]

    return (
        <>
            <Helmet>
                <title> It Due Dates | Gems Clip </title>
            </Helmet>

            <SearchTable
                columns={columns}
                data={dueData}
                searchItems={['itr1OpeningDate', 'itr1DueDate']}
                addNewPopup={addNewPan}
                onRowClickHandler={onRowClickHandler}
            />
            <DrawerIt show={show} setShow={setShow} drawerType={drawerType} rowValue={rowValue} />
        </>
    )
}

export default It;