import { Col, Form, Row } from 'antd';
import InputContainer from '../../../components/InputContainer/InputContainer';

const ClientDetails = ({ mode, drawerType, data, formErrors, handleChange }) => (
  <>
    <div className="header">Client Details</div>
    <hr />
    <div className="d-flex flex-wrap">
      <InputContainer
        disabled={!!(mode === 'view' && drawerType === 'existing')}
        label="Client ID"
        type="text"
        id="clientId"
        value={data?.clientId}
        name="clientId"
        picker="date"
        onChange={handleChange}
        placeholder="Enter Client ID"
        containerClassName={`${mode === 'view' ? 'custom-disable' : ''} pe-3 pb-1 col-md-6`}
      />
      <InputContainer
        required
        label="Client Name"
        type="text"
        id="clientName"
        name="clientName"
        error={formErrors?.clientName}
        value={data?.clientName}
        onChange={handleChange}
        placeholder="Enter Client Name"
        containerClassName={`${mode === 'view' ? 'custom-disable' : ''} pe-3 pb-1 col-md-6`}
        disabled={!!(mode === 'view' && drawerType === 'existing')}
      />

      <InputContainer
        disabled={!!(mode === 'view' && drawerType === 'existing')}
        label="PAN"
        type="text"
        id="pan"
        name="pan"
        value={data?.pan}
        onChange={handleChange}
        placeholder="Enter PAN No"
        containerClassName={`${mode === 'view' ? 'custom-disable' : ''} pe-3 pb-1 col-md-6`}
        maxLength="10"
      />
      <InputContainer
        disabled={!!(mode === 'view' && drawerType === 'existing')}
        label="Aadhaar"
        type="number"
        id="aadhaar"
        name="aadhaar"
        value={data?.aadhaar}
        onChange={handleChange}
        placeholder="Enter Aadhaar No"
        containerClassName={`${mode === 'view' ? 'custom-disable' : ''} pe-3 pb-1 col-md-6`}
      />
      <InputContainer
        disabled={!!(mode === 'view' && drawerType === 'existing')}
        label=" Email ID"
        type="text"
        id="emailId"
        name="emailId"
        value={data?.emailId}
        onChange={handleChange}
        placeholder="Enter Email ID"
        containerClassName={`${mode === 'view' ? 'custom-disable' : ''} pe-3 pb-1 col-md-6`}
      />
      <InputContainer
        disabled={!!(mode === 'view' && drawerType === 'existing')}
        label="Mobile Number"
        type="number"
        id="mobileNumber"
        name="mobileNumber"
        value={data?.mobileNumber}
        onChange={handleChange}
        placeholder="Enter Mobile Number"
        containerClassName={`${mode === 'view' ? 'custom-disable' : ''} pe-3 pb-1 col-md-6`}
      />
      <InputContainer
        disabled={!!(mode === 'view' && drawerType === 'existing')}
        label="Company Name"
        type="text"
        id="companyName"
        name="companyName"
        value={data?.companyName}
        error={formErrors?.companyName}
        onChange={handleChange}
        placeholder="Enter Company Name"
        containerClassName={`${mode === 'view' ? 'custom-disable' : ''} pe-3 pb-1 col-md-6`}
      />
      <InputContainer
        disabled={!!(mode === 'view' && drawerType === 'existing')}
        label="Designation"
        type="text"
        id="designation"
        name="designation"
        value={data?.designation}
        onChange={handleChange}
        placeholder="Enter Designation"
        containerClassName={`${mode === 'view' ? 'custom-disable' : ''} pe-3 pb-1 col-md-6`}
      />
    </div>
  </>
);
export default ClientDetails;
